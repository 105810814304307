import { DatepickerComponent } from './datepicker.component';
import { TimepickerComponent } from './timepicker.component';

/**
 * A function for initialization ePortalInitDatePicker
 * @function ePortalInitDatePicker
 * @param {string} locale - The language locale value. Default 'sk'.
 * @param {string} element - The query element (id, class, name, ...).
 * @param {DatepickerSettings} customSettings - The customSettings object.
 */
window.ePortalInitDatePicker = function (locale, element, customSettings) {
  let datepicker = new DatepickerComponent(element, customSettings);
  if (locale !== null) {
    datepicker.setLocale(locale);
  }
}

window.ePortalInitTimePicker = function (element, customSettings) {
  new TimepickerComponent(element, customSettings);
}

// Tow arrow select component init
window.selectArrowButtonsClick = function (selectPickerId) {
  let selectpicker = $('#' + selectPickerId + ' select.selectpicker');
  if (selectpicker.length == 0) {
    return;
  }
  $('#' + selectPickerId + ' select.selectpicker').o2mobileselectpicker();

  let leftButton = $('#' + selectPickerId).prev().children('.btn-arrow');
  let rightButton = $('#' + selectPickerId).next().children('.btn-arrow');

  $(leftButton).click(function () {
    let i = $('#' + selectPickerId + ' select option:selected').index();

    if (i > 0) {
      i--;
      $('#' + selectPickerId + ' select').prop('selectedIndex', i);
      let text = $('#' + selectPickerId + ' select option:selected').text();
      $('#' + selectPickerId + ' div button span.filter-option').text(text);
    }
    selectpicker.change();
  });

  $(rightButton).click(function () {

    let i = $('#' + selectPickerId + ' select option:selected').index();
    let last = $('#' + selectPickerId + ' select option').last().index();

    if (i < last) {
      i++;
      $('#' + selectPickerId + ' select').prop('selectedIndex', i);
      let text = $('#' + selectPickerId + ' select option:selected').text();
      $('#' + selectPickerId + ' div button span.filter-option').text(text);
    }
    selectpicker.change();
  });

  selectpicker.change(function () {
    let that = $(this);
    let wrapper = that.closest('.tow-arrow-menu');
    let currentValue = that.val();

    wrapper.find('.btn-arrow').removeClass('disabled');
    if (currentValue == that.find('option:first').val()) {
      wrapper.find('.left-arrow-btn > .btn-arrow:eq(0)').addClass('disabled');
    }
    if (currentValue == that.find('option:last').val()) {
      wrapper.find('.right-arrow-btn > .btn-arrow:eq(0)').addClass('disabled');
    }
    $('#' + selectPickerId + ' select.selectpicker').o2mobileselectpicker('refresh');
  });
}

window.ePortalInitMultiselect = function (element, customTexts) {
  let texts = customTexts;

  if (!texts) {
    texts = {
      item: 'Vyberte',
      items: '',
      moreitems: ''
    };
  }
  if (!texts.item) {
    texts.item = 'Vyberte';
  }
  if (!texts.items) {
    texts.items = '';
  }
  if (!texts.moreitems) {
    texts.moreitems = '';
  }
  element.multiselect({
    templates: {
      button: '<button type="button" class="multiselect dropdown-toggle btn btn-default" data-toggle="dropdown" title="Vyberte"><span class="multiselect-selected-text">Vyberte</span> <b class="glyphicon glyphicon-chevron-down"></b></button>'
    },
    buttonText: function (options) {
      let textMsg;
      if (options.length == 0) {
        textMsg = texts.item;
      }
      else if (options.length > 2) {
        textMsg = options.length + ((options.length >= 5) ? (' vybran\u00FDch ' + texts.moreitems) : (' vybran\u00E9 ' + texts.items));
      }
      else {
        let selected = '';
        options.each(function () {
          selected += $(this).text() + ', ';
        });
        textMsg = selected.substr(0, selected.length - 2);
      }

      return textMsg;
    },
    nonSelectedText: texts.item
  });
};

// Nested select component init
window.ePortalInitMultiDropdown = function (element, options) {
  element.selectpicker(options);

  let parent = element.parent().find('.bootstrap-select');
  let radio = '<input type="radio" />';
  $('a', parent).prepend(radio);
  $('a', parent).on('focus', function () {
    $('a input[type=radio]', parent).prop('checked', false);
    $(this).find('input[type=radio]').prop('checked', true);
  });

  element.change(function () {
    setSelectButtonText();
  });

  let optIndex = 0;
  $('li', parent).each(function (index) {
    let hasOpt = $(this).find('a').hasClass('opt');
    let hasDt = $(this).find('dt').length > 0;
    if (hasOpt && !hasDt) {
      $(this).addClass('child closed');
      $(this).attr('data-root', optIndex);
    }
    if (hasDt) {
      optIndex += 1;
      $(this).addClass('root closed');
      $(this).attr('data-root', optIndex);
      $(this).find('a.opt').addClass('child closed');
      $(this).find('a.opt').attr('data-root', optIndex);
    }
  });

  $('li.root dt', parent).click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    let li = $(this).closest('li');
    let rootIndex = li.data('root');
    let liClosed = li.hasClass('closed');
    $('.child, .root', parent).addClass('closed');
    if (liClosed) {
      li.removeClass('closed');
      $('.child[data-root=' + rootIndex + ']', parent).toggleClass('closed');
    }
  });

  let preselected = $('li.selected', parent);
  if (preselected.length) {
    let root = preselected.data('root');
    if (root) {
      parent.find('li.root[data-root=' + root + '] dt').click();
    }
    setSelectButtonText();
    multilineHandler(element);
  }

  $(window).on('resize', function () {
    multilineHandler(element);
  });


  element.on('change', function () {
    multilineHandler(this);
  })

  function setSelectButtonText() {
    if (element.val()) {
      let option = element.find('option[value="' + element.val() + '"]');
      let alttext = option.data('alttext');
      if (alttext) {
        element.parent().find('.bootstrap-select button span.filter-option').text(alttext);
      }
    }
  }

  function multilineHandler($dropdown) {
    let multiSelect = $($dropdown).next('.bootstrap-select.multilevel');
    let icon = multiSelect.children('button').children('span.glyphicon');
    let dropdown = multiSelect.children('.dropdown-menu');

    if (multiSelect.hasClass('multiline')) {
      icon.css('line-height', icon.height() + 'px');
      dropdown.css('top', (multiSelect.height() - 3) + 'px');
    }
  }
}

window.ePortalInitSlideRange = function (sliderRange) {
  let rangeWrapper = sliderRange.closest('.range-wrapper');
  sliderRange.on('slidechange', function (event, ui) {
    rangeWrapper.find('input[name="slider-min-value"]').val(ui.values[0]);
    rangeWrapper.find('input[name="slider-max-value"]').val(ui.values[1]);
  });

  let minValue = sliderRange.data('val-min');
  let maxValue = sliderRange.data('val-max');
  let max = sliderRange.data('max');
  let min = sliderRange.data('min');
  let unit = sliderRange.data('unit');
  let tooltipFirst = $('<div class="range-tooltip range-tooltip--first"></div>');
  let tooltipSecond = $('<div class="range-tooltip range-tooltip--second"></div>');
  slideRange(minValue, maxValue);

  bindInputToSlider('.data-amount-from', 0);
  bindInputToSlider('.data-amount-to', 1);

  $('.slider-min', sliderRange).html('<span class="from-text">od </span>' + min + ' ' + unit);
  $('.slider-max', sliderRange).html('<span class="to-text">do </span>' + max + ' ' + unit);

  function slideRange(valMin1, valMin2) {
    let max = sliderRange.data('max');
    let min = sliderRange.data('min');
    sliderRange.slider({
      range: true,
      min: min,
      max: max,
      values: [valMin1, valMin2],
      slide: function (event, ui) {
        $('.form-control.from').val(ui.values[0]);
        $('.form-control.to').val(ui.values[1]);
        let unit = sliderRange.data('unit');
        if (!unit) {
          unit = '&euro;';
        }
        $(this).closest('.range-wrapper').find('span.slider-max').html('<span class="to-text">do </span>' + ui.values[1] + '&nbsp;' + unit);
        $(this).closest('.range-wrapper').find('span.slider-min').html('<span class="from-text">od </span>' + ui.values[0] + '&nbsp;' + unit);
        $(this).closest('.range-wrapper').find('.form-control.data-amount-from').val(ui.values[0]);
        $(this).closest('.range-wrapper').find('.form-control.data-amount-to').val(ui.values[1]);

        tooltipFirst.text(ui.values[0]);
        tooltipSecond.text(ui.values[1]);
      }
    });

    $('.range-wrapper .ui-slider-handle:eq(0)').append(tooltipFirst);
    $('.range-wrapper .ui-slider-handle:eq(1)').append(tooltipSecond);
  }

  function bindInputToSlider(inputSelector, inputNumber) {
    $('.range-wrapper ' + inputSelector).keyup(function () {
      let $this = $(this),
        val = parseInt($this.val(), 10) || 0;
      if (val > max) {
        val = max;
      } else if (val < 0) {
        val = 0;
      }
      $this.val(val);
      $this.closest('.range-wrapper').find('.slider-range').slider('values', inputNumber, val);
      if (inputNumber == 1) {
        $this.closest('.range-wrapper').find('span.slider-max').html(val + '&nbsp;' + unit);
      } else {
        $this.closest('.range-wrapper').find('span.slider-min').html(val + '&nbsp;' + unit);
      }
    });
  }
}

window.ePortalInitSlideDateRange = function (sliderRange) {
  let sliderRangeWrapper = sliderRange.closest('.slider-date-range');
  sliderRange.on('slidechange', function (event, ui) {
    sliderRangeWrapper.find('input[name=slider-min-value]').val(ui.values[0]);
    sliderRangeWrapper.find('input[name=slider-max-value]').val(ui.values[1]);
  });

  let datePattern = sliderRange.data('date-pattern');
  if (!datePattern) {
    datePattern = 'DD.MM.YYYY';
  }
  let minValue = moment(sliderRange.data('val-min'), datePattern).toDate();
  let maxValue = moment(sliderRange.data('val-max'), datePattern).toDate();
  slideRange(minValue, maxValue, datePattern);

  let min = moment(sliderRange.data('min'), datePattern).toDate();
  let max = moment(sliderRange.data('max'), datePattern).toDate();

  $('.slider-min', sliderRange).text(formattedDate(minValue));
  $('.slider-max', sliderRange).text(formattedDate(maxValue));

  function formattedDate(inputDate) {
    let outputDate = inputDate || new Date;

    const day = outputDate.getDate();
    const month = outputDate.getMonth() + 1;
    const year = outputDate.getFullYear();

    return `${day}.${month}.${year}`;
  }

  function slideRange(minValue, maxValue, datePattern) {
    let min = moment(sliderRange.data('min'), datePattern).toDate();
    let max = moment(sliderRange.data('max'), datePattern).toDate();

    sliderRange.slider({
      range: true,
      min: min.getTime() / 1000,
      max: max.getTime() / 1000,
      step: 86400,
      values: [minValue.getTime() / 1000, maxValue.getTime() / 1000],
      slide: function (event, ui) {
        $('.form-control.from').val(new Date(ui.values[0] * 1000));
        $('.form-control.to').val(new Date(ui.values[1] * 1000));
        $(this).closest('.range-wrapper').find('span.slider-max').html(formattedDate(new Date(ui.values[1] * 1000)));
        $(this).closest('.range-wrapper').find('span.slider-min').html(formattedDate(new Date(ui.values[0] * 1000)));
      }
    });
  }
}

if (typeof window.ePortalTooltip == "undefined") {
  window.ePortalTooltip = new EPortalTooltip();
}

function EPortalTooltip() {
  function tooltipOrient($tooltip) {

    if (!$tooltip) {
      return;
    }

    var $win = $(window),
      $view = {
        top: $win.scrollTop(),
        bottom: $win.scrollTop() + $win.height(),
        left: $win.scrollLeft(),
        right: $win.scrollLeft() + $win.width()
      },
      $elem = {
        top: $tooltip.offset().top,
        bottom: $tooltip.offset().top + $tooltip.height(),
        left: $tooltip.offset().left,
        right: $tooltip.offset().left + $tooltip.width()
      };

    // overflowing top
    if ($elem.top < $view.top) {
      $tooltip.removeClass('top').addClass('bottom');
    }

    // overflowing bottom
    if ($elem.bottom > $view.bottom) {
      $tooltip.removeClass('bottom').addClass('top');
    }

    // overflowing left
    if ($elem.left < $view.left) {
      $tooltip.removeClass('left').addClass('right');
    }

    // overflowing right
    if ($elem.right > $view.right) {
      $tooltip.removeClass('right').addClass('left');
    }
  }

  function showTooltip($tooltip) {
    $tooltip.stop();

    // orient tooltip
    tooltipOrient($tooltip);

    // show it
    //$tooltip.animate({ opacity: 1 }, 200);
  }

  function handlerIn() {
    var $tooltip = $(this).find(".tooltip, .tooltip-lg");
    $tooltip.each(function (index, el) {
      showTooltip($(el));
    });
  }

  this.registerTooltip = function (element) {
    $(element).unbind().hover(handlerIn, handlerOut);
  };

  function handlerOut() {
    //$(this).find(".tooltip, .tooltip-lg").stop().fadeOut(200);
  }

  this.prepareAppendedTooltip = function ($container) {
    var tooltipTemplate = $('<div class="question-mark tooltip-appended"><div class="tooltip"></div></div>');
    $container.append(tooltipTemplate);
    var $tooltip = $container.find('.tooltip-appended');
    $tooltip.unbind('mouseenter').unbind('mouseleave');
    $tooltip.hover(
      handlerIn,
      function () {
        $(this).find(".tooltip").stop().fadeOut(200, function () {
          $tooltip.offset({ top: 0, left: 0 });
        });
      });
  }

  this.bindOffsetTooltip = function ($questionMark, callback) {
    var $tooltip = $('.tooltip-appended');
    $questionMark.hover(
      function () {
        var $clone = $(this);
        var offset = $(this).offset();
        $tooltip.offset(offset);
        $tooltip.css({ width: $clone.css('width') });
        $tooltip.find(".tooltip").html($clone.find(".tooltip").html());
        if (callback) {
          callback();
        }
      }
    );
  }

  $(document).ready(function () {
    $(".question-mark:not(.no-tooltip), .icon-info:not(.no-tooltip), .tooltip-wrap").on('mouseenter', handlerIn);
    $(".question-mark:not(.no-tooltip), .icon-info:not(.no-tooltip), .tooltip-wrap").on('mouseleave', handlerOut);

    // set tooltip default orientation to "top" if not defined
    $('.tooltip').each(function (index, el) {
      var tooltip = $(this);
      if (!tooltip.hasClass('top') && !tooltip.hasClass('bottom')) {
        tooltip.addClass('top');
      }
    });
  });
}

if (typeof window.EPortalTooltip == "undefined") {
  window.EPortalTooltip = function () {
    function tooltipOrient($tooltip) {

      if (!$tooltip) {
        return;
      }

      var $win = $(window),
        $view = {
          top: $win.scrollTop(),
          bottom: $win.scrollTop() + $win.height(),
          left: $win.scrollLeft(),
          right: $win.scrollLeft() + $win.width()
        },
        $elem = {
          top: $tooltip.offset().top,
          bottom: $tooltip.offset().top + $tooltip.height(),
          left: $tooltip.offset().left,
          right: $tooltip.offset().left + $tooltip.width()
        };

      // overflowing top
      if ($elem.top < $view.top) {
        $tooltip.removeClass('top').addClass('bottom');
      }

      // overflowing bottom
      if ($elem.bottom > $view.bottom) {
        $tooltip.removeClass('bottom').addClass('top');
      }

      // overflowing left
      if ($elem.left < $view.left) {
        $tooltip.removeClass('left').addClass('right');
      }

      // overflowing right
      if ($elem.right > $view.right) {
        $tooltip.removeClass('right').addClass('left');
      }
    }

    function showTooltip($tooltip) {
      $tooltip.stop()
        .css({
          opacity: '0',
          display: 'block'
        });
      // orient tooltip
      tooltipOrient($tooltip);

      // show it
      //$tooltip.animate({ opacity: 1 }, 200);
    }

    function handlerIn() {
      var $tooltip = $(this).find(".tooltip, .tooltip-lg");
      $tooltip.each(function (index, el) {
        showTooltip($(el));
      });
    }

    this.registerTooltip = function (element) {
      $(element).unbind().hover(handlerIn, handlerOut);
    }

    function handlerOut() {
      $(this).find(".tooltip, .tooltip-lg").stop().fadeOut(200);
    }

    this.prepareAppendedTooltip = function ($container) {
      var tooltipTemplate = $('<div class="question-mark tooltip-appended"><div class="tooltip"></div></div>');
      $container.append(tooltipTemplate);
      var $tooltip = $container.find('.tooltip-appended');
      $tooltip.unbind('mouseenter').unbind('mouseleave');
      $tooltip.hover(
        handlerIn,
        function () {
          $(this).find(".tooltip").stop().fadeOut(200, function () {
            $tooltip.offset({ top: 0, left: 0 });
          });
        });
    }

    this.bindOffsetTooltip = function ($questionMark, callback) {
      var $tooltip = $('.tooltip-appended');
      $questionMark.hover(
        function () {
          var $clone = $(this);
          var offset = $(this).offset();
          $tooltip.offset(offset);
          $tooltip.css({ width: $clone.css('width') });
          $tooltip.find(".tooltip").html($clone.find(".tooltip").html());
          if (callback) {
            callback();
          }
        }
      );
    }

    $(document).ready(function () {
      $(".question-mark:not(.no-tooltip), .icon-info:not(.no-tooltip), .tooltip-wrap").on('mouseenter', handlerIn);
      $(".question-mark:not(.no-tooltip), .icon-info:not(.no-tooltip), .tooltip-wrap").on('mouseleave', handlerOut);

      // set tooltip default orientation to "top" if not defined
      $('.tooltip').each(function (index, el) {
        var tooltip = $(this);
        if (!tooltip.hasClass('top') && !tooltip.hasClass('bottom')) {
          tooltip.addClass('top');
        }
      });
    });
  }
}

window.ePortalInitDropdownBox = function (element) {
  let boxes = element || $('.dropdown-box');
  boxes.each(function (index, el) {
    let box = $(el);

    if (typeof box.data('dropdown-box') == "undefined") {
      box.data('dropdown-box', 'initialized');

      box.find('.dropdown-title').click(function () {
        $(this).find('.drop-btn').toggleClass('opened');
        $(this).find('.drop-btn').toggleClass('closed');
        $(this).toggleClass('opened');
        //$(this).parent().find('.box-content').slideToggle(400);
      });
    }
  });
}

window.ePortalTabCollapseSelect = function ($tabs, containerClass) {
  $tabs.addClass('hidden-xs');
  var $tabContent = $tabs.next();
  var $lis = $tabs.find('li');
  var $selectWrapper = $('<div class="row tab-select-container"><div class="visible-xs ' + containerClass + '"><select class="tab-select"></select></div></div>');
  var $select = $selectWrapper.find('select');
  $tabContent.prepend($selectWrapper);
  for (var i = 0; i < $lis.length; i++) {
    var $option = $('<option></option>');
    $option.text($($lis[i]).find('a').text());
    $option.attr('value', $($lis[i]).find('a').attr('href'))
    $option.appendTo($select);
  }
  $select.val($tabs.find('li.active a').attr('href'));
  $select.selectpicker();
  $select.change(function () {
    $tabs.find('a[href="' + $(this).val() + '"]').click();
  });

  function setCollapsedTabs($tabContent) {
    if ($(window).width() >= 768) {
      $tabContent.removeClass('collapsed');
    } else {
      $tabContent.addClass('collapsed');
    }
  }

  setCollapsedTabs($tabContent);
  $(window).resize(function () {
    setCollapsedTabs($tabContent);
  });
}

window.isDesktop = function () {
  var check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
  })(navigator.userAgent || navigator.vendor || window.opera);
  return !check;
}

window.ePortalReinitTableFixedColumns = function () {
  if (typeof window.originalTable != 'undefined' && !jQuery.isEmptyObject(window.originalTable)) {
    jQuery.each(window.originalTable, function (index, value) {
      var fixedTableWrapper = jQuery('.wrapper-' + index);
      fixedTableWrapper.hide();

      var wrappedOriginalTable = $(window.originalTable[index]);
      wrappedOriginalTable.insertBefore(fixedTableWrapper);
      fixedTableWrapper.remove();
    });
    window.originalTables = {};
  }
}


// Table fixed column component init
var originalTable = {};
window.ePortalInitTableFixedColumns = function ($table) {

  jQuery.fn.hasHorizontalScrollBar = function () {
    return this.get(0).scrollWidth > this.innerWidth();
  }

  var WRAPPER = $('<div/>').addClass('fixed-table-wrapper');
  var SHADE = $('<div/>').addClass('table-fixed-icon');

  function fixate(i, e) {
    //DOM elements
    var original = $(e);
    original.uniqueId();
    originalTable[original.attr('id')] = original.parent().clone();
    var clone = original.clone().addClass('fixed-table');
    clone.attr('id', clone.attr('id') + '-fixed');
    var bootstrapWrapper = original.parent();

    if (!bootstrapWrapper.hasHorizontalScrollBar()) {
      bootstrapWrapper.addClass('wrapper-' + original.attr('id'));
      return;
    }

    var wrapper = WRAPPER.clone();
    wrapper.addClass('wrapper-' + original.attr('id'));
    var isDesktop = window.isDesktop();
    if (bootstrapWrapper.hasClass('table-wrapper')) {
      wrapper.addClass('rounded');
    }
    //Markup
    bootstrapWrapper.wrap(wrapper);
    clone.insertBefore(bootstrapWrapper);
    //Tables dom and styles
    var columnsCount = columns(original);
    DOMClone(clone, columnsCount);
    CSS(original, clone, bootstrapWrapper);
    DOMOriginal(original, columnsCount);

    if (isDesktop) {
      desktopScrollbar(original, bootstrapWrapper, wrapper);
    } else {
      var left = SHADE.clone().addClass('left');
      var right = SHADE.clone().addClass('right');
      bootstrapWrapper.parent().append(left).append(right);
      right.show();
      left.hide();
      mobileScrollbar(original, bootstrapWrapper, wrapper);
      scrollEvent(left, right, original, bootstrapWrapper);
    }
  }

  function mobileScrollbar(original, bootstrapWrapper, wrapper) {
    var height = original.height();
    bootstrapWrapper.height(height + 30);
    bootstrapWrapper.parent().height(height);
  }

  function desktopScrollbar(original, bootstrapWrapper, wrapper) {
    var height = original.height();
    bootstrapWrapper.height(height).parent().height(height);
  }

  function scrollEvent(left, right, original, bootstrapWrapper) {
    left.css('left', bootstrapWrapper.css('margin-left'));
    var maxRight = original.width() - bootstrapWrapper.width() - 6;
    bootstrapWrapper.scroll(function () {
      var scrollLeft = bootstrapWrapper.scrollLeft();
      if (scrollLeft > 0) {
        left.show();
      }
      if (scrollLeft < maxRight) {
        right.show();
      }
      if (scrollLeft == 0) {
        left.hide();
      }
      if (scrollLeft >= maxRight) {
        right.hide();
      }
    })
  }

  function columns(table) {
    var columns = parseInt(table.attr('data-columns'));
    if (columns > 0) {
      return columns;
    } else {
      return 1;
    }
  }

  function DOMClone(clone, columnsCount) {
    // Remove not fixed from clone
    clone.find('th:gt(' + (columnsCount - 1) + ')').remove();
    clone.find('tbody>tr').each(function (i, e) {
      $(e).find('td:gt(' + (columnsCount - 1) + ')').remove();
    })

  }

  function DOMOriginal(original, columnsCount) {
    // Remove fixed from original
    original.find('th:lt(' + (columnsCount) + ')').remove();
    original.find('tbody>tr').each(function (i, e) {
      $(e).find('td:lt(' + (columnsCount) + ')').remove();
    })
  }

  function CSS(original, clone, bootstrapWrapper) {
    //height
    clone.find('tr').each(function (i, e) {
      var cloneElement = $(e);
      var originalElement = original.find('tr:eq(' + i + ')')
      var cloneHeight = cloneElement.height();
      var originalHeight = originalElement.height();
      if (cloneHeight <= originalHeight) {
        originalElement.height(originalHeight);
        cloneElement.height(originalHeight);
      } else {
        originalElement.height(cloneHeight);
        cloneElement.height(cloneHeight);
      }
    });
    //width
    var width = 0;
    clone.find('th').each(function (i, e) {
      var header = original.find('th:eq(' + i + ')');
      width += header.outerWidth(true) + parseInt(header.css('border-spacing'));
    });
    width += 1;
    clone.width(width);
    //scroll margin-left
    var wrapperWidth = bootstrapWrapper.width();

    if($(window).width() > 1199){
      bootstrapWrapper.css({
        'margin-left': width + 13 + 'px',
        'width': wrapperWidth - width - 13 + 'px'
      });
    }
    else{
      bootstrapWrapper.css({
        'margin-left': width + 1 + 'px',
        'width': wrapperWidth - width - 1 + 'px'
      });
    }
  }
  $table.each(fixate);
}

window.initCustomCKEditorStyles = function () {
  var $iFrame = $('.cke_wysiwyg_frame');
  var stylesheets = ['bootstrap.min.css', 'bootstrap-o2-custom.css', 'bootstrap-select.min.css'];
  var iframeHead = $iFrame.contents().find('head');
  for (var i = 0; i < stylesheets.length; i++) {
    var $headLinks = $('head link');
    for (var j = 0; j < $headLinks.length; j++) {
      var headLink = $headLinks[j];
      if (typeof $(headLink).attr('href') !== typeof undefined && ($(headLink).attr('href').includes(stylesheets[i]))) {
        iframeHead.append($(headLink).clone());
      }
    }
  }
}

window.initInput = function ($input) {
  $.each($input, function () {
    if ($(this).val() != "") {
      $(this).addClass('filled');
    }
  });

  $input.on('input change focusout', function () {
    if ($(this).val() != "") {
      $(this).addClass('filled');
    } else {
      $(this).removeClass('filled');
    }

    $.each($input, function () {
      if ($(this).val() != "") {
        $(this).addClass('filled');
      }
    });
  });
}


window.ePortalInitExpandableTableRow = function ($table) {
  $table.on('click', 'tr.slide-trigger', function () {
    var $tr = $(this);
    var row = $($table).DataTable().row($tr);
    var dropBtn = $tr.find('.drop-btn');

    if (row.child.isShown()) {
      dropBtn.addClass('closed');
      dropBtn.removeClass('opened');
      $tr.next("tr").find(".dropdown-table_content").slideToggle(500, 'easeOutQuad', function () {
        $tr.removeClass('opened');
        row.child.hide();
      });
    } else {
      $tr.addClass('opened');
      dropBtn.addClass('opened');
      dropBtn.removeClass('closed');
      var detailId = $tr.data('detail-id');
      var $detail = $('.dropdown-table_details > div[data-table-detail="' + detailId + '"]').clone();
      row.child($detail).show();
      row.child().addClass('has-content');
      $tr.next("tr").find(".dropdown-table_content").slideToggle(500, 'easeOutQuad');
    }
  });
}

// TODO: remove source component
// window.ePortalInitExpandableTree = function ($elements) {
//   $.each($elements, function () {
//     var $wrapper = $(this);
//     var $tree = $('.tree-root', $wrapper).o4tree();
//     $('.unpack-all', $wrapper).click(function (e) {
//       e.preventDefault();
//       $tree.o4tree('openAll');
//     });
//     $('.unpack-branch', $wrapper).click(function (e) {
//       e.preventDefault();
//       $tree.o4tree('openActiveBranch');
//     });
//     $('.pack-all', $wrapper).click(function (e) {
//       e.preventDefault();
//       $tree.o4tree('closeAll');
//     });
//   });
// }

window.ePortalInitFormElements = function (element) {
  // init input (filled class)
  initInput(element.find('input[type="text"], input[type="email"], input[type="password"], textarea, input[type="tel"], input[type="url"]'));

  // init selectpickers
  (element.find('.selectpicker')).selectpicker();

  // init validations
  ePortalInitValidations(element);

  // init tooltips
  window.EPortalTooltip();

  // init dropdown boxes
  ePortalInitDropdownBox(element.find('.dropdown-box'));

  // init date pickers
  (element.find('.datepicker')).each(function (index, el) {
    var $input = $(el);
    if (!$input.data('datepicker')) {
      ePortalInitDatePicker('sk', $input);
    }
  });
}

// Datatables component init
window.ePortalInitDatatable = function (table, customSettings) {
  let settings = {
    language: {
      search: '_INPUT_',
      searchPlaceholder: 'Hľadať v tabuľke',
      sLengthMenu: '_MENU_ <label class="control-label" for="selectbox">Počet záznamov</label'
    },
    bProcessing: false, // disabled 'processing' indicator (e.g. for sorting)
    bFilter: false, // disabled filtering of data
    sPaginationType: 'guidelines', // custom O2 pagination type
    bLengthChange: false, // disables option to select size of the page
    iDisplayLength: 10, // number of records displayed per page
    bStateSave: false, // uses cookies to persist state of table (e.g. filtering)
    sDom: 'frt<"row"<"col-sm-3 col-sm-push-9"l><"col-sm-6"p>>', // datatables render template
    columnDefs: [
      {
        'bSortable': false,
        targets: ['nosort']
      }
    ]
  };

  // extend settings if needed
  if (customSettings) {
    $.extend(settings, customSettings);
  }

  table.DataTable(settings);

  let tablesLength = table.parent('.dataTables_wrapper').find('.dataTables_length select');

  // show length select like bootstrap-selectpicker
  if (tablesLength) {
    tablesLength.parents('.dataTables_length').addClass('form-horizontal');
    tablesLength.parent('label').addClass('form-group');
    tablesLength.selectpicker();
  }
}

window.AlertType = {
  INFO: 1,
  SUCCESS: 2,
  WARNING: 3,
  ERROR: 4,
  properties: {
    1: { name: 'INFO', value: 1, cssClass: 'alert-info' },
    2: { name: 'SUCCESS', value: 2, cssClass: 'alert-success' },
    3: { name: 'WARNING', value: 3, cssClass: 'alert-warning' },
    4: { name: 'ERROR', value: 4, cssClass: 'alert-danger' }
  }
};

/**
 * @param source source of event
 * @param content HTML cotent
 * @param level type of popup (INFO, SUCCESS, WARNING, ERROR)
 * @param opts options
 */
window.ePortalAlert = function (source, content, level, opts) {

  // default options
  var options = {
    hideDelay: 10000
  };

  // combine with parameters
  if (opts != null) {
    $.extend(options, opts);
  }

  // create alert
  var alertElement = $('<div class="alert gv2 animate-bounce ' + AlertType.properties[level].cssClass + '" >' +
    '<button type="button" class="close"><span>×</span></button>' +
    '<div class="alert-content">' +
    '<p>' + content + '</p>' +
    '</div>' +
    '</div>');

  // add element into main content
  if (alertElement != null) {
    // TODO pakr: fix title banner with special title layout instead of using funky/trashy jquery absolute positioning on title page
    var $titleBanner = $('#title-banner');
    if ($titleBanner.length > 0) {
      $titleBanner.after(alertElement);
    } else {
      $('#main').prepend(alertElement);
    }
    alertElement.delay(options.hideDelay).hide(1000);
    alertElement.find('button').click(function () {
      alertElement.remove();
    });
  }
}

window.setGuidelinesClass = function (table, filterWrapperClass) {
  $('.' + filterWrapperClass + ' input').addClass('form-control');
  table.addClass('table');
  table.find('thead').addClass('gray');
}

window.ePortalInitPromoContent = function (element, options) {
  let now = new Date();
  // input duration in minutes or default value - 5 minutes
  let duration = (options.duration || 5) * 1000 * 60;

  if (!(now >= options.dateFrom && now <= options.dateTo)) {
    $(element).css('display', 'none');
  }

  let checkPromoInterval = setInterval(function () {
    now = new Date();

    if (now >= options.dateFrom && now <= options.dateTo) {
      $(element).css('display', 'block');
    } else if (now > options.dateTo) {
      $(element).css('display', 'none');
      clearInterval(checkPromoInterval);
    }

  }, duration);
}

//global ajax overlay calling - start function
window.ePortalInitStartOverlay = function () {
  $('body').prepend(
    `<div class="global-overlay">
      <div class="global-overlay__box">
        <div class="global-overlay__icon">
          <span class="o2f-loader o2f-spin o2-color--white"></span>
        </div>
      </div>
    </div>`);
}

//global ajax overlay calling - start function just inside of element
window.ePortalInitStartElementOverlay = function () {
  $('.global-overlay--element-parent').prepend(
    `<div class="global-overlay global-overlay--element">
      <div class="global-overlay__box">
        <div class="global-overlay__icon">
          <span class="o2f-loader o2f-spin o2-color--white"></span>
        </div>
      </div>
    </div>`);
}

//global ajax overlay calling - stop overlay
window.ePortalInitStopOverlay = function () {
  $('body').find('.global-overlay').remove();
}

// Change readonly selectpicker to active
window.ePortalInitActivateSelectpicker = function (el) {
  $(el).siblings('.bootstrap-select').removeClass('select--readonly');
  $(el).siblings('.selectpicker').removeClass('select--readonly');
  $(el).hide();
}

// Basic O2 Swiper inicialization
window.ePortalInitSwiper = function (element, customSettings) {
  let elementObj = $(element),
    settings = {
      lazyLoading: true,
      loop: true,
      autoplay: {
        stopOnLastSlide: false,
        disableOnInteraction: false,
        delay: 5000,
      },
      speed: 500,
      effect: 'slide',
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.o2f-chevron-right',
        prevEl: '.o2f-chevron-left',
      }
    }

  if (customSettings) {
    $.extend(settings, customSettings);
  }

  let swiper = new Swiper(elementObj, settings);

  elementObj.hover(function () {
    swiper.autoplay.stop();
  }, function () {
    swiper.autoplay.start();
  });

  if (elementObj.attr('data-type') === 'title-banner') {

    let swiperContainerHeight = elementObj.height(),
      swiperSlide = elementObj.find('.swiper-slide'),
      swiperSlideHeight = swiperSlide.height();

    if ($(window).width() < 768) {
      if (swiperSlideHeight < swiperContainerHeight) {
        swiperSlide.css('height', swiperContainerHeight);
      }
    } else {
      $('.swiper-slide').height(elementObj.height());
    }
  }
}

// Prepended input init if prepended symbol has bigger width
window.ePortalInitPrependedInput = function () {
  $('.input-group .input-group-addon').each(function (index, item) {
    $(item).siblings('.label-prepend').css('margin-left', $(item).width() + 21);
  });
}

// Selectpicker with dynamic paging
window.ePortalInitSelectpickerDynamicPaging = function () {
  $('.selectpicker[data-dynamic-paging="true"]').each(function () {
    var select = $(this),
      bsSelect = select.siblings('.bootstrap-select'),
      bsSelectDropdown = bsSelect.find('.dropdown-menu:not(.inner)'),
      bsSelectSearchInput = bsSelect.find('.bootstrap-select-searchbox > input'),
      options = select.find('option'),
      bsOptions = bsSelectDropdown.find('li'),
      optRangeMin = 3,
      optRangeMax = 5,
      noDataMessage = '<p class="mt-3 no-data-message">Nenašli sa žiadne dáta.</p>',
      currentPage = parseInt(select.attr("data-page")) + 1,
      totalPages,
      dataPerPage = select.attr('data-size'),
      countPages,
      jsonUrl = select.attr('data-ajax-url'),
      parameters,
      paginationButton,
      thisPagination,
      paginationMarkup;

    if (select.attr('data-ajax-url')) {
      totalPages = select.attr('data-total');
      countPages = Math.ceil(totalPages / dataPerPage);
    } else {
      totalPages = options.length;
      countPages = Math.ceil(totalPages / dataPerPage);
    }

    paginationMarkup =
      `<form role="form" class="pagination-form" data-o2-id="pagination-form-1">
      <div id="pagination-default" class="pagination">
        <div class="pagenum-wrap">
          <a class="prev fa fa-angle-left" data-min="1"></a>
        </div>
        <div class="pagenum-wrap">
          <a class="next fa fa-angle-right" data-max="' + countPages + '"></a>
        </div>
      </div>
    </form>`;

    // Hide search input
    function displaySearchInput() {
      if (totalPages < 2) {
        bsSelectSearchInput.parent().hide();
      }
      else {
        bsSelectSearchInput.parent().show();
      }
    }

    // Define pagination markup
    function appendPaginationMarkupToSelect() {
      $(paginationMarkup).appendTo(bsSelectDropdown);
    }

    // Hide select pagination
    function hideSelectPagination() {
      bsSelectDropdown.removeClass('has-pagination');
      bsSelect.find('.pagination').hide();
    }

    // Show select pagination
    function showSelectPagination() {
      bsSelectDropdown.addClass('has-pagination');
      bsSelect.find('.pagination').show();
    }

    // Disable Pagination
    function disablePagination(element) {
      bsSelect.find('.pagenum-wrap > ' + element).parent().addClass('disabled');
    }

    // Enable Pagination
    function enablePagination(element) {
      bsSelect.find('.pagenum-wrap > ' + element).parent().removeClass('disabled');
    }

    // set pagination disabled if the data-page is out of pagination range
    function isPageOnPaginationRange() {
      if (countPages > 1) {
        showSelectPagination();
        displaySearchInput();
        if (select.attr('data-page') < 1) {
          disablePagination('.prev');
        }
        else if (currentPage >= countPages) {
          disablePagination('.next');
        }
        else {
          enablePagination('a');
        }
      }
      else {
        hideSelectPagination();
        displaySearchInput();
      }
    }

    // Define Json GET request on pagination click
    function getJsonData() {
      $.ajax({
        url: jsonUrl,
        method: 'GET',
        dataType: 'json',
        data: parameters,
        success: function (result) {
          removeSelectOptions();
          for (var key in result) {
            $('<option value=' + result[key].msisdn + '>' + '0' + Number(result[key].msisdn).toLocaleString('sk') + ' | ' + result[key].currentTariffId + '</option>').appendTo(select);
          }
          select.selectpicker('refresh');
          ePortalInitStopOverlay();
        },
        error: function (req, err) {
          ePortalInitStopOverlay();
        }
      });
    }

    // Define Json GET request on search
    function getJsonDataSearch() {
      $.ajax({
        url: jsonUrl,
        method: 'GET',
        dataType: 'json',
        data: parameters,
        success: function (result) {
          removeSelectOptions();
          if (!$.isArray(result) || result.length > 0) {
            for (var key in result) {
              $('<option value=' + result[key].msisdn + '>' + '0' + Number(result[key].msisdn).toLocaleString('sk') + ' | ' + result[key].currentTariffId + '</option>').appendTo(select);
            }
          } else {
            $(noDataMessage).appendTo(bsSelect.find('.bootstrap-select-searchbox'));
          }
          select.selectpicker('refresh');
          ePortalInitStopOverlay();
        },
        error: function (req, err) {
          ePortalInitStopOverlay();
        }
      });
    }

    // Remove all options when start typing
    function removeSelectOptions() {
      select.find('option').remove();
      bsSelect.find('ul.dropdown-menu').children('li').remove();
    }

    // Custom Json Search function
    function search(force) {
      var existingString = bsSelectSearchInput.val().replace(/\s/g, '');
      if (existingString.startsWith('09')) {
        existingString = existingString.substr(1);
      }
      if (existingString.length != 0) {
        disablePagination('a');
        if (!force && existingString.length < 3) return;
        parameters = "size=" + dataPerPage + "&page=" + 0 + "&msisdn=" + existingString;
        getJsonDataSearch();
      }
      else {
        parameters = "size=" + dataPerPage + "&page=" + (currentPage - 1) + "&msisdn";
        getJsonData();
        enablePagination('a');
        isPageOnPaginationRange();
      }
    }

    // If select doesn't have data-ajax-url, paginate just from existing options
    function paginateOffline() {
      optRangeMin = parseInt(select.attr('data-page')) * parseInt(dataPerPage);
      optRangeMax = parseInt(select.attr('data-page')) * parseInt(dataPerPage) + parseInt(dataPerPage);
      bsOptions.hide();
      bsOptions.slice(optRangeMin, optRangeMax).show();
      ePortalInitStopOverlay();
    }

    appendPaginationMarkupToSelect();
    isPageOnPaginationRange();

    // Use search to search in database
    bsSelectSearchInput.keyup(function (e) {
      if (select.attr('data-ajax-url')) {
        removeSelectOptions();
        bsSelect.find('p.no-data-message').remove();
        clearTimeout($.data(this, 'timer'));
        if (e.keyCode == 13) {
          search(true);
        } else {
          $(this).data('timer', setTimeout(search, 500));
        }
      } else {
        bsOptions.show();

        if (bsSelectSearchInput.val() != '') {
          hideSelectPagination();
        } else {
          select.attr('data-page', '0');
          currentPage = parseInt(select.attr('data-page')) + 1;
          enablePagination('a');
          isPageOnPaginationRange();
          paginateOffline();
        }
      }
    });

    // Define events after click on pagination buttons
    bsSelect.find('.pagination a').click(function (e) {
      paginationButton = $(this);
      thisPagination = paginationButton.parents('.pagination');
      e.preventDefault();

      // Stop propagate link. This don't close the options box
      paginationButton.parent('.pagenum-wrap').on({
        'click': function (e) {
          e.stopPropagation();
        }
      });

      if (!$(this).parent().hasClass('disabled')) {
        if (thisPagination) {
          if (paginationButton.hasClass('prev')) {
            currentPage = currentPage - 1;
            select.attr('data-page', (parseInt(select.attr('data-page'))) - 1)
          } else if (paginationButton.hasClass('next')) {
            currentPage = currentPage + 1;
            select.attr('data-page', (parseInt(select.attr('data-page'))) + 1)
          }
          let min = thisPagination.find('a:first').data('min');
          let max = thisPagination.find('a:last').data('max');
          if (currentPage >= min && currentPage <= max) {
            thisPagination.find('.pagenum-wrap').removeClass('disabled');
            if (currentPage == min) {
              thisPagination.find('.pagenum-wrap:eq(0)').addClass('disabled');
            }
            if (currentPage == max) {
              thisPagination.find('.pagenum-wrap:eq(1)').addClass('disabled');
            }
          }

          // Set overlay parent and start it
          bsSelectDropdown.addClass('global-overlay--element-parent');
          ePortalInitStartElementOverlay();

          if (select.attr('data-ajax-url')) {
            // Ajax call after click on button
            parameters = "size=" + dataPerPage + "&page=" + (currentPage - 1) + "&msisdn";
            getJsonData();
          } else {
            paginateOffline();
          }
        }
      }
    });
  });
}

window.ePortalClosePopup = function () {
  $.fancybox.close();
  return false;
}

window.fancyboxSettings = function ($popupContent) {
  return {
    href: '#' + $popupContent.attr('id'),
    parent: 'body',
    openMethod: 'slidebottomIn',
    closeMethod: 'slidebottomOut',
    openSpeed: 300,
    closeSpeed: 300,
    autoCenter: true, // fixes pop-ups position in responsive mobile view
    onUpdate: function () {
      var windowsWidth = $(window).width();
      var el = $('.fancybox-wrap .fancybox-popup-content');

      if (windowsWidth > 580 && el.hasClass('popup-small')) {
        $('.fancybox-wrap .fancybox-inner').css('width', '520px');
        el.css('width', '520px');
      } else if (windowsWidth > 780 && el.hasClass('popup-large')) {
        $('.fancybox-wrap .fancybox-inner').css('width', '720px');
        el.css('width', '720px');
      } else {
        var outer = $('.fancybox-wrap .fancybox-inner').width();
        $('.fancybox-wrap .fancybox-popup-content').css('width', outer + 'px');
      }
    }
  }
}

window.ePortalRegisterPopup = function ($fireButton, $popupContent) {
  if (!$popupContent) {
    $popupContent = $fireButton.parent() ? $fireButton.parent().find('.fancybox-popup-content') : $popupContent;
  }

  $fireButton.fancybox(fancyboxSettings($popupContent));
}

window.ePortalShowPopup = function ($popupContent) {
  $.fancybox(fancyboxSettings($popupContent));
}

// Read more wrapper - if content overflowing wrapper, than function creates read more link
window.readMoreWrapper = function (elWrapper, elWrapperH) {
  $(elWrapper + ':not(.read-more-wrapper)').addClass('read-more-wrapper');
  elWrapperH = elWrapperH || 300;
  $(elWrapper).attr('data-max-height', elWrapperH);

  $(elWrapper).on('click', '.read-more__btn', function(){
    var btnWrapper = $(this).parents(elWrapper);

    if (!btnWrapper.hasClass('opened')) {
      $(this).text('Menej');
      btnWrapper.css('max-height', 'none');
      btnWrapper.addClass('opened');
    }
    else {
      $(this).text('Viac');
      btnWrapper.css('max-height', btnWrapper.data('max-height'));
      btnWrapper.removeClass('opened');
    }
  });

    if ($(elWrapper).height() > $(elWrapper).data('max-height')) {
      $(elWrapper).addClass('has-overflow');
      $(elWrapper).css('max-height', $(elWrapper).data('max-height'));
      $(elWrapper).append('<a class="read-more__btn">Viac</a>');
    }
}

window.ePortalInitVideoTabs = function (videoBoxSelector) {
  $(videoBoxSelector).fancybox({
    mouseWheel: false,
    beforeShow: function () {
      var alt = this.element.find('img').attr('alt');
      var title = this.element.find('img').attr('title');

      this.inner.find('img').attr('alt', alt);
      this.inner.find('img').attr('title', title);
    },
    afterLoad: function () {
      this.title = (this.title ? '' + this.title + '<br />' : '') + '<strong>' + (this.index + 1) + ' / ' + this.group.length + '</strong>';
    }
  });
}

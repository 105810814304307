declare var $: any;

/**
 * Class representing a TimepickerComponent
 */
export class TimepickerComponent {
  element: string;
  timePickerBtn: any;
  customSettings: TimepickerSettings;
  settings: TimepickerSettings = {
    showPeriodLabels: false,
    showOn: 'both',
    showLeadingZero: false,
    rows: 6,
    defaultTime: '',
    beforeShow: function () {
      let parentElementWidth = $(this).parent()[0].clientWidth - 2;

      setTimeout(function () {
        $('.ui-timepicker > .ui-timepicker-table').css('width', parentElementWidth);
        $('.ui-timepicker-hours table.ui-timepicker tbody').prepend('<tr class="ui-timepicker-space"><td></td><td></td><td></td><td></td></tr>');
        $('.ui-timepicker-hours table.ui-timepicker tbody').append('<tr class="ui-timepicker-space"><td></td><td></td><td></td><td></td></tr>');

        $('.ui-timepicker-minutes table.ui-timepicker tbody').prepend('<tr class="ui-timepicker-space"><td></td><td></td></tr>');
        $('.ui-timepicker-minutes table.ui-timepicker tbody').append('<tr class="ui-timepicker-space"><td></td><td></td></tr>');
      });
    }
  }

  /**
   * Create a DatepickerComponent.
   * @param {string} element - The query element (id, class, name, ...).
   * @param {TimepickerSettings} customSettings - The customSettings object.
   */
  constructor(element: string, customSettings: TimepickerSettings) {
    this.element = element;
    this.setCustomSettings(customSettings);
    this.init();
  }

  /**
  * A function to init Datepicker component.
  * @private
  */
  private init(): void {
    this.settings.button = $(this.element).siblings('.ui-picker-btn');
    $(this.element).timepicker(this.settings);
  }

  /**
  * A function to set TimepickerComponent customSettings.
  * @param {TimepickerSettings} customSettings - Custom settings based on TimepickerSettings interface.
  */
  setCustomSettings(customSettings: TimepickerSettings): void {
    if (customSettings != null) {
      $.extend(this.settings, customSettings);
    }
  }
}

/**
 * Interface for TimepickerComponent class that represent settings.
 * @interface TimepickerSettings
 */
interface TimepickerSettings {
  timeSeparator?: string;           // The character to use to separate hours and minutes. (default: ':')
  showLeadingZero?: boolean;        // Define whether or not to show a leading zero for hours < 10.
  showMinutesLeadingZero?: boolean; // Define whether or not to show a leading zero for minutes < 10.
  showPeriod?: boolean;            // Define whether or not to show AM/PM with selected time. (default: false)
  showPeriodLabels?: boolean;       // Define if the AM/PM labels on the left are displayed. (default: true)
  periodSeparator?: string;         // The character to use to separate the time from the time period.
  altField?: string; // Define an alternate input to parse selected time to
  defaultTime?: string;         // Used as default time when input field is empty or for inline timePicker
  // (set to 'now' for the current time, '' for no highlighted time,

  // trigger options
  showOn?: string;              // Define when the timepicker is shown.
  // 'focus': when the input gets focus, 'button' when the button trigger element is clicked,
  // 'both': when the input gets focus and when the button is clicked.
  button?: any;                 // jQuery selector that acts as button trigger. ex: '#trigger_button'

  // Localization
  hourText?: string;             // Define the locale text for "Hours"
  minuteText?: string;         // Define the locale text for "Minute"
  amPmText?: Array<string>;       // Define the locale text for periods

  // Position
  myPosition?: string;       // Corner of the dialog to position, used with the jQuery UI Position utility if present.
  atPosition?: string;    // Corner of the input to position

  // Events
  beforeShow?: any; // Callback function executed before the timepicker is rendered and displayed.
  onSelect?: any;  // Define a callback function when an hour / minutes is selected.
  onClose?: any;     // Define a callback function when the timepicker is closed.
  onHourShow?: any;      // Define a callback to enable / disable certain hours. ex: function onHourShow(hour)
  onMinuteShow?: any;   // Define a callback to enable / disable certain minutes. ex: function onMinuteShow(hour, minute)

  // custom hours and minutes
  hours?: any;
  minutes?: {
    starts: number,                // First displayed minute
    ends: number,                 // Last displayed minute
    interval: number,              // Interval of displayed minutes
    manual: Array<any>                // Optional extra entries for minutes
  },
  rows?: number;                      // Number of rows for the input tables, minimum 2, makes more sense if you use multiple of 2
  showHours?: boolean;              // Define if the hours section is displayed or not. Set to false to get a minute only dialog
  showMinutes?: boolean;            // Define if the minutes section is displayed or not. Set to false to get an hour only dialog

  // Min and Max time
  minTime?: any;
  maxTime?: any;
  // buttons
  showCloseButton?: boolean;       // shows an OK button to confirm the edit
  closeButtonText?: string;      // Text for the confirmation button (ok button)
  showNowButton?: boolean;         // Shows the 'now' button
  nowButtonText?: string;         // Text for the now button
  showDeselectButton?: boolean;    // Shows the deselect time button
  deselectButtonText?: string; // Text for the deselect button
}

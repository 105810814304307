window.maskPhone = function (element, customMaskPhone) {
  let el = document.querySelector(element);
  let phone = '{+}000 000 000 000';

  if (el === null) {
    return null;
  }

  if (customMaskPhone !== undefined) {
    phone = customMaskPhone;
  }

  // TODO pripravit aj update mask pre pevnu linku
  let maskOptions = {
    mask: phone,
    lazy: false,
    placeholderChar: '_'
  };

  let mask = new IMask(el, maskOptions);
  return mask;
}

window.maskIBAN = function (element, customMaskIBAN) {
  // iban length https://bank.codes/iban/examples/

  let el = document.querySelector(element);
  let iban = 'aa00 0000 0000 0000 0000 0000';

  if (el === null) {
    return null;
  }

  if (customMaskIBAN !== undefined) {
    iban = customMaskIBAN;
  }

  let maskOptions = {
    mask: iban,
    lazy: false,
    placeholderChar: '_'
  };

  let mask = new IMask(el, maskOptions);
  return mask;
}

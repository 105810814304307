declare var $: any;

/**
 * Class representing a DatepickerComponent
 */
export class DatepickerComponent {
  element: string;
  customSettings: DatepickerSettings;
  locale: string;

  settings: DatepickerSettings = {
    yearRange: '-10:+1',
    showButtonPanel: false,
    changeMonth: true,
    changeYear: true,
    dateFormat: 'd.m.yy',
    onSelect: function (dateText: any, inst: any) {
      $(inst.input[0]).focusout();
      if ($(inst.input[0]).hasClass('error')) {
        $(inst.input[0]).removeClass('error');
        $('div[for=' + $(inst.input[0]).attr('id') + ']').hide();
      }
    },
    beforeShow: function (input: any, inst: any) {
      $(input).parent().addClass('datepicker-label');    // jumping label fix on loosing focus

      if (!$(input).parent().parent().find('.datepicker-container').length) {
        $(input).parent().parent().append('<div class="datepicker-container"></div>');
      }
      let datepickerContainer = $(input).parent().parent().find('div.datepicker-container');
      $(input).datepicker('widget').appendTo(datepickerContainer);

      setTimeout(function () {
        inst.dpDiv.css({ top: 0, left: 0 });
        if ($(inst.dpDiv).find('select').length > 1) {
          /* If there are more then one select, then it means that datepicker has month and year,
          then set 'half-width' class for setting width 50% per each selector */
          $(datepickerContainer).addClass('half-width');
        }
      }, 0);

      if (!$(input).val()) {
        $(input).addClass('filled');
      }
    },
    onClose: function (dateText: any, inst: any) {
      // jumping label fix on loosing focus
      $(inst.input[0]).parent().removeClass('datepicker-label');

      if ($(inst.input[0]).val()) {
        $(inst.input[0]).addClass('filled');
      }
    }
  };

  /**
   * Create a DatepickerComponent.
   * @param {string} element - The query element (id, class, name, ...).
   * @param {DatepickerSettings} customSettings - The customSettings object.
   * @param {string} locale - The language locale value. Default 'sk'.
   */
  constructor(element: string, customSettings?: DatepickerSettings, locale: string = 'sk') {
    this.element = element;
    this.setCustomSettings(customSettings);
    this.setLocale(locale);
    this.init();
  }

  /**
  * A function to init Datepicker component.
  * @private
  */
  private init(): void {
    // init date picker, update if initialized
    let datepicker = $(this.element);
    if (datepicker.hasClass('hasDatepicker')) {
      datepicker.datepicker('destroy');
    }
    datepicker.datepicker(this.settings);

    // TODO remove: .input-group-addon.icon-calendar part is DEPRECATED
    $(this.element).siblings('.input-group-addon.ui-picker-btn, .input-group-addon.icon-calendar').click(function () {
      $(this.element)
        .parent()
        .parent()
        .find('.ui-datepicker')
        .is(':visible') ? $(this).datepicker('hide') : $(this).datepicker('show');
    });
  }

  /**
  * A function to set DatepickerComponent customSettings.
  * @param {DatepickerSettings} customSettings - Custom settings based on DatepickerSettings interface.
  */
  setCustomSettings(customSettings: DatepickerSettings): void {
    // extend settings if needed
    if (customSettings != null) {
      $.extend(this.settings, customSettings);
    }
  }

  /**
  * A function to set Datepicker locale.
  * @param {string} locale - Language locale parameter.
  */
  setLocale(locale: string): void {
    // set for all datepickers
    $.datepicker.setDefaults($.datepicker.regional[locale]);
  }

  /**
  * A function to hide Datepicker label error on validation.
  * @param {string} input - Input element.
  */
  hideErrorMessage(input: string): void {
    $(input).focusout();
    if ($(input).hasClass('error')) {
      $(input).removeClass('error');
      $('div[for=' + $(input).attr('id') + ']').hide();
    }
  }
}

/**
 * Interface for DatepickerComponent class that represent settings.
 * @interface DatepickerSettings
 */
interface DatepickerSettings {
  altField?: any;
  altFormat?: any;
  appendText?: any;
  autoSize?: any;
  beforeShow?: any;
  beforeShowDay?: any;
  buttonImage?: any;
  buttonImageOnly?: any;
  buttonText?: any;
  calculateWeek?: any;
  changeMonth?: any;
  changeYear?: any;
  closeText?: any;
  constrainInput?: any;
  currentText?: any;
  dateFormat?: any;
  dayNames?: any;
  dayNamesMin?: any;
  dayNamesShort?: any;
  defaultDate?: any;
  duration?: any;
  firstDay?: any;
  gotoCurrent?: any;
  hideIfNoPrevNext?: any;
  isRTL?: any;
  maxDate?: any;
  minDate?: any;
  monthNames?: any;
  monthNamesShort?: any;
  navigationAsDateFormat?: any;
  nextText?: any;
  numberOfMonths?: any;
  onChangeMonthYear?: any;
  onClose?: any;
  onSelect?: any;
  prevText?: any;
  selectOtherMonths?: any;
  shortYearCutoff?: any;
  showAnim?: any;
  showButtonPanel?: any;
  showCurrentAtPos?: any;
  showMonthAfterYear?: any;
  showOn?: any;
  showOptions?: any;
  showOtherMonths?: any;
  showWeek?: any;
  stepMonths?: any;
  weekHeader?: any;
  yearRange?: any;
  yearSuffix?: any;
}

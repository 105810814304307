window.hideEnvelopeByParameter = function(cssToHide){
  var css = cssToHide,
      cssHeadTag = document.head || document.getElementsByTagName('head')[0],
      cssStyleTag = document.createElement('style');
      cssStyleTag.type = 'text/css';

  function hideEnvelope(){
    cssHeadTag.appendChild(cssStyleTag);
    cssStyleTag.appendChild(document.createTextNode(css));
  }
  
  if (window.location.search.indexOf('source=credit_topup_mapp') > -1 || window.location.search.indexOf('hideEnvelope=true') > -1) {
    sessionStorage.setItem('hideEnvelope', 'true');
    hideEnvelope();
  }

  else if (window.location.search.indexOf('hideEnvelope=false') > -1) {
    sessionStorage.removeItem('hideEnvelope');
  }

  else if (sessionStorage.getItem('hideEnvelope', 'true')) {
    hideEnvelope();
  }
}

/**
 * prepares the ajax options object for $ ajax requests. <p/>
 * <p>
 * NOTE: this method requires the portlet markup to be wrapped in a container of
 * id="#portletId", otherwise it won't work.
 *
 * @param portletId unique portlet id to identify the div to reload with ajax result
 */
function ajaxPortletOptions(portletId) {
  return {
    // on request start
    beforeSend: function () {
      // replace content with "loading..."
      // TODO Michal Zubek: nicer loading animation
      $('#' + portletId).html('<div class="ajax-loading">&nbsp;</div>');
    },
    // the page was loaded correctly
    success: function (html) {
      var container = $('#' + portletId);
      // remember the scripts in the incoming request
      var scripts = html.match(/<script[^>]*>.*<\/script>/gm);
      if (null != scripts) {
        // rip the scripts from the incoming request
        html = html.replace(/<script.*<\/script>/gm, '');
      }
      // replace the container with the markup generated by the portlet
      container.replaceWith(html);
      // if scripts were found
      if (null != scripts) {
        container.append(scripts.join('\n'));
      }
    },
    // an error occured
    error: function () {
      // TODO Michal Zubek: error in liferay markup
      // $( "#" + portletId ).html( "An error occured." );
    }
  };
}

/**
 * Binds a form submission event to a given form in a given portlet.
 *
 * @param portletId
 *            unixue portlet ID to target the container
 * @param url
 *            url for the ajax call
 * @param formname
 *            the form to bind the submit event to
 */
function ajaxSubmitForm(portletId, url, formname) {
  // when the document is ready to be traversed: bind the submit event
  $(document).ready(function () {
    // get the form $ object
    // TODO Michal Zubek: fix form name in selector
    var forms = $('#' + portletId + " " + (null == formname ? 'form' : '.' + formname));
    var options = ajaxPortletOptions(portletId);
    options.url = url;
    // add the submit event
    forms.ajaxForm(options);
  });
}

/**
 * Generate new captcha and set the image/name attribute
 */
$(document).ready(function () {
  $('.captcha .regenerate').click(function () {
    var regenerateButton = $(this);
    $.ajax({
      url: '/liferay-portlets/generateCaptchaServlet',
      dataType: 'json',
      success: function (newCaptcha) {
        var inputBox = $('input[name*="captcha["]', regenerateButton.parent().parent());
        // different structure used in login JSPs, so we
        // have to check for number of objects found
        if (inputBox.length > 0) {
          $(inputBox.get(0)).attr('name', 'captcha[' + newCaptcha.id + ']');
        }
        else {
          $('input[name*="EPORTAL_CAPTCHA_ID"]', regenerateButton.parent().parent()).attr('value', newCaptcha.id);
        }
        $('img', regenerateButton.parent()).attr('src', '/liferay-portlets/captcha?string=' +
          newCaptcha.id);
      }
    });
    return false;
  });
});


function searchAdr() {
  var adr = document.getElementById('id_searchAddressText').value;
  searchAddress(adr);
}

function onClickTextAutocompleteByStreet(portletId, formName, streetField, townField, zipField) {
  var form = '#' + portletId + ' form[formId=' + formName + ']';

  var autocompleteDataId = 'ui-autocomplete';
  $(document).ready(function () {
    $(form + ' :input[name="' + streetField + '"]').autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/eportal-liferaytheme/addressRegisterServlet',
          dataType: 'text',
          data: {
            type: 'street',
            q: removeDiacritics(request.term)
          },
          success: function (data) {
            data = data.split(/\n\r\n|\r\n|\r|\n/);
            var result = [];
            for (var i = 0; i < data.length; i++) {
              var value = data[i];
              if (value.replace(/^\s+|\s+$/g, '').length > 0) {
                result.push(value);
              }
            }
            response(result);
          }
        });
      },
      minLength: 1,
      delay: 200,
      contentType: 'text/plain; charset=utf-8',
      select: function (event, ui) {
        var res = String(ui.item.value).split(',');
        var streetValue = res[0].replace(/^\s*|\s*$/g, "");
        var townValue = res[1].replace(/^\s*|\s*$/g, "");
        var zipValue = res[2].replace(/^\s*|\s*$/g, "");
        streetValue = streetValue.replace(/&nbsp;/g, ' ');
        streetValue = streetValue.replace(/<b>|<\/b>/g, "");
        townValue = replaceSpacesNbsp(townValue);
        zipValue = replaceSpacesNbsp(zipValue);

        $(form + ' :input[name="' + streetField + '"]').val(streetValue);
        $(form + ' :input[name="' + townField + '"]').val(townValue);
        $(form + ' :input[name="' + zipField + '"]').val(zipValue);
        $(form + ' :input[name="' + streetField + '"]').valid();
        $(form + ' :input[name="' + townField + '"]').valid();
        $(form + ' :input[name="' + zipField + '"]').valid();
        return false;
      },
      focus: function (event, ui) { return false; },
      open: function (event, ui) {
        $(this).addClass('autocomplete-opened');
        var inputWidth = $(this).outerWidth();
        $('.ui-autocomplete').css({ width: inputWidth });
        var that = $(this);
        $('.ui-autocomplete .ui-autocomplete-close').click(function () {
          that.removeClass('autocomplete-opened');
        });
      },
      close: function (event, ui) {
        $(this).removeClass('autocomplete-opened');
      }
    }).data(autocompleteDataId)._renderItem = function (ul, item) {
      return $('<li></li>')
        .data('item.autocomplete', item)
        .append('<a>' + item.label + '</a>')
        .appendTo(ul);
    };
  });
}

function onClickTextAutocompleteByTown(portletId, formName, streetField, townField, zipField) {
  var form = '#' + portletId + ' form[formId=' + formName + ']';

  var autocompleteDataId = 'ui-autocomplete';
  $(document).ready(function () {
    $(form + ' :input[name="' + townField + '"]').autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/eportal-liferaytheme/addressRegisterServlet',
          dataType: 'text',
          data: {
            type: 'town',
            q: removeDiacritics(request.term)
          },
          success: function (data) {
            data = data.split(/\n\r\n|\r\n|\r|\n/);
            var result = [];
            for (var i = 0; i < data.length; i++) {
              var value = data[i];
              if (value.replace(/^\s+|\s+$/g, '').length > 0) {
                result.push(value);
              }
            }
            response(result);
          }
        });
      },
      minLength: 1,
      delay: 200,
      contentType: 'text/plain; charset=utf-8',
      select: function (event, ui) {
        var res = String(ui.item.value).split(',');
        var townValue = res[0].replace(/^\s*|\s*$/g, "");
        var zipValue = res[1].replace(/^\s*|\s*$/g, "");
        townValue = townValue.replace(/<b>|<\/b>/g, "");
        townValue = townValue.replace(/&nbsp;/g, ' ');
        zipValue = replaceSpacesNbsp(zipValue);

        $(form + ' :input[name="' + townField + '"]').val(townValue);
        $(form + ' :input[name="' + townField + '"]').valid();

        if (zipField != null) {
          $(form + ' :input[name="' + zipField + '"]').val(zipValue);
          $(form + ' :input[name="' + zipField + '"]').valid();
        }
        return false;
      },
      focus: function (event, ui) { return false; },
      open: function (event, ui) {
        $(this).addClass('autocomplete-opened');
        var inputWidth = $(this).outerWidth();
        $('.ui-autocomplete').css({ width: inputWidth });
        var that = $(this);
        $('.ui-autocomplete .ui-autocomplete-close').click(function () {
          that.removeClass('autocomplete-opened');
        });
      },
      close: function (event, ui) {
        $(this).removeClass('autocomplete-opened');
      }
    }).data(autocompleteDataId)._renderItem = function (ul, item) {
      return $('<li></li>')
        .data('item.autocomplete', item)
        .append('<a>' + item.label + '</a>')
        .appendTo(ul);
    };
  });
}

var _cityField;
var _zipField;
var _streetField;
var _formName;
var xmlhttp;
var _div_top;
var _div_table;

function onClickTextFieldForm(portletId, formName, inputField, cityField, zipField, streetField) {
  _cityField = cityField;
  _zipField = zipField;
  _streetField = streetField;

  $(document).ready(function () {
    var form = '#' + portletId + ' form[formId=' + formName + ']';
    _formName = form;
    $(form + ' :input[name="' + inputField + '"]').click(function () {
      $("select").hide();
      $(form).append('<div id="addressSearchForm" class="address_search_form">' +
        '<div class="closeSearchWindow" onclick="closeSearchWindow();">X</div>' +
        createTopTable() + '<div id="address_result" class="address_result"></div></div>');
      $(form + ' :input[name="searchAddressText"]').focus();
    });
  });
}

function createSearchForm() {
  return createTopTable();
}

function createTopTable() {
  return `<table border="0" cellpadding="0" cellspacing="0">
            <tr><th colspan="3">Výber adresy</th></tr>
            <tr>
              <td>Hľadaná adresa</td>
              <td><input id="id_searchAddressText" type="text" name="searchAddressText" class="searchText"/></td>
              <td class="searchAddressButton">
                <input type="button" id="id_searchAddressButton" name="searchAddressButton" onclick="searchAdr();"  value="Vyhľadať" />
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="address_info">Zadajte adresu v tvare: ulica, mesto</td>
              <td></td>
            </tr>
          </table><br/>`;
}

function clearTopTable() {
  document.getElementById('address_result').innerHTML = '';
}

function searchAddress(address) {
  clearTopTable();
  xmlhttp = GetXmlHttpObject();
  if (xmlhttp == null) {
    console.error("Your browser does not support XMLHTTP!");
    return;
  }
  var url = '/eportal-liferaytheme/addressRegisterServlet';
  url = url + '?addressText=' + address;
  xmlhttp.onreadystatechange = stateChanged;
  xmlhttp.open('GET', url, true);
  xmlhttp.send(null);
}


function stateChanged() {
  if (xmlhttp.readyState === 4) {
    var response = xmlhttp.responseXML;
    var addresses = response.getElementsByTagName('Address');
    var resultTab = '';
    if (addresses.length > 0) {
      resultTab = '<table border="0"><tr class="tr_header"><th>Ulica</th><th>Mesto</th><th>PSČ</th><th></th>';
    }
    for (var i = 0; i < addresses.length; i++) {
      var street = getNodeValue(addresses[i], 'Street');
      var town = getNodeValue(addresses[i], 'Town');
      var zip = getNodeValue(addresses[i], 'Zip');
      resultTab =
        resultTab + '<tr class="tr_data" onclick="javascript:setAddress(\'' + town + '\',\'' + zip + '\',\'' +
        street + '\');\">' + '<td class="td_data">' + street + '</td><td class="td_data">' + town +
        '</td><td class="td_data">' + zip + '</td></tr>';
    }
    if (addresses.length > 0) {
      resultTab = resultTab + '</table>';
    }
    else {
      resultTab = resultTab + '<tr class="tr_data"><td colspan="3">Nenašla sa žiadna adresa</td></tr></table>';
    }
    document.getElementById('address_result').innerHTML = resultTab;
  }
}

function setAddress(city, zip, street) {
  $(_formName + ' :input[name="' + _cityField + '"]').val(city);
  $(_formName + ' :input[name="' + _zipField + '"]').val(zip);
  $(_formName + ' :input[name="' + _streetField + '"]').val(street);
  $(_formName + ' :input[name="' + _cityField + '"]').focus();
  closeSearchWindow();
}

function closeSearchWindow() {
  document.getElementById('addressSearchForm').style.display = 'none';
  var div = document.getElementById('addressSearchForm');
  div.parentNode.removeChild(div);
  $('select').show();
}

// Ajax call for selector view
function refreshSelectorView(dir, formid, find_msisdn) {
  $(document).ready(function () {
    var ajaxOptions = {
      async: true,
      data: { direction: dir, form_id: formid, sv_msisdn: find_msisdn },
      dataType: 'json',
      url: '/delegate/selectorchecker',
      success: function (data) {
        $('#lv_msisdn_list li').remove();

        var lastpage = data.lastPage;
        var pagenumber = data.pageNumber;

        // add new page items
        var msisdns = data.msisdns;

        for (var i = 0; i < msisdns.length; i++) {
          var msisdn = msisdns[i];
          $('#lv_msisdn_list').append(
            '<li onclick="hidablePanelLiClickFunction(\'' + msisdn.number + '\')"><a>'
            + msisdn.number + '</a></li>');
        }

        if (msisdns.length === 0) {
          $('#lv_msisdn_list').append('<li class="NoListItem"' +
            'onclick="refreshSelectorView( -1, \'' + formid + '\',\'' + $("#sv_msisdn").val() + '\')" >' +
            '<div style ="color:red;">Zadan&#233; &#269;&#237;slo nen&#225;jden&#233;</div>' +
            '<div style="color:#0086C3;"> Zobraz v&#353;etky &#269;&#237;sla </div></li>');
          $('#buttonPanel').hide();
        } else if (pagenumber === "-1") {
          $('#lv_msisdn_list').append('<li  class="NoListItem" ' +
            'onclick="{ refreshSelectorView( -1, \'' + formid + '\',\'' + $("#sv_msisdn").val() + '\'); ' +
            '$(\'#sv_msisdn\').val(\'\'); }  "> ' +
            '<div style="color:#0086C3;"> Zobraz v&#353;etky &#269;&#237;sla </div></li>');
          $('#buttonPanel').hide();
        } else {
          $('#buttonPanel').show();
        }

        if (lastpage == "true") { $('.rightButton').hide(); } else { $('.rightButton').show(); }
        if (pagenumber == "0") { $('.leftButton').hide(); } else { $('.leftButton').show(); }
      },
      complete: function () {
        checkLiWidth();
        $('#loadingIndicator').hide();
      },
      error: function (xhr, textStatus, errorThrown) {
        console.error('chyba pri načítavaní, skontrolujte prosím Vaše pripojenie na internet a prihlásenie.');
      }
    };

    $.ajax(ajaxOptions);
  });
}


/**
 * format the autocomplete item - will be displayed as <i>contact name [(msisdn)]</i> in the suggestion box
 *
 * @param row row data
 * @return contact name [(msisdn)]
 */
function formatAutocompleteItemSK(row) {
  // only for nubmers with slovak prefix 421
  row = row.split('|');
  var result;
  if (row.length > 2) {
    if (row[1] !== '+421') {
      result = '';
    } else {
      result = row[2] + ' (' + row[1] + ' ' + row[0] + ')';
    }

  } else {
    if (row[0] !== '+421') {
      result = '';
    } else {
      result = row[1] + ' ' + row[0];
    }
  }
  return result;
}
function contactsAutocompleteForSelector(msisdnInputName) {
  $().ready(function () {
    $('input[name="' + msisdnInputName + '"]', null).autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/delegate/smsContacts',
          dataType: 'text',
          data: {
            q: request.term
          },
          success: function (data) {
            data = data.split(/\n\r\n|\r\n|\r|\n/);
            var result = [];
            for (var i = 0; i < data.length; i++) {
              var value = data[i];
              if (value.replace(/^\s+|\s+$/g, '').length > 0) {
                value = formatAutocompleteItemSK(value);
                if (value.length > 0) result.push(value);
              }
            }
            response(result);
          }
        });
      },
      open: function () {
        if ($(this).hasClass('form-control')) {
          $(this).addClass('autocomplete-opened');
          $(this).autocomplete('widget').css('z-index', 100);
          var inputWidth = $(this).outerWidth();
          $('.ui-autocomplete').css({ width: inputWidth });
          var that = $(this);
          $('.ui-autocomplete .ui-autocomplete-close').click(function () {
            that.removeClass('autocomplete-opened');
          });
        }
      },
      minLength: 0,
      delay: 50,
      contentType: 'text/plain; charset=utf-8',
      select: function (event, ui) {
        var res = String(ui.item.value);
        res = res.replace("(", "");
        res = res.replace(")", "");
        res = res.replace(/\)/g, "");
        res = res.split(" ");
        $('input[name="' + msisdnInputName + '"]', null).val(res[res.length - 1]);
        $('input[name="' + msisdnInputName + '"]', null).closest('form').validate().form();
        return false;
      },
      focus: function (event, ui) {
        return false;
      },
      close: function (event, ui) {
        $(this).removeClass('autocomplete-opened');
      }
    })
      .focus(function () {
        var styl = $('.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all').css('display');
        if (styl === 'none') {
          $(this).autocomplete('search', '');
        }
      });
  });
}

jQuery.extend(jQuery.expr[':'], {
  "dataStartsWith": function (el, i, p, n) {
    var pCamel = p[3].replace(/-([a-z])/ig, function (m, $1) {
      return $1.toUpperCase();
    });
    return Object.keys(el.dataset).some(function (i, v) {
      return i.indexOf(pCamel) > -1;
    });
  }
});

var $questionMark = "";
var message = "";

$.validator.setDefaults({
  errorElement: "p",
  errorClass: "has-error",
  validClass: "has-success",
  ignoreTitle: true,
  focusInvalid: false,
  ignore: ":hidden, .ignore, .tt-hint",
  errorPlacement: function (error, element) {
    $(error).removeAttr('class').removeAttr('id');

    var value = $(element).val(),
      required = $(element).data('rule-required');
    var $questionMark = $(element).siblings('.question-mark');

    if (required && (value == null || value == '') && !$(element).data('msg') && !$(element).data('msg-required')) {
      $(element).removeClass('tooltip-visible');
      $questionMark.addClass('question-mark-required');
      return;
    }

    $(element).addClass('tooltip-visible');
    $questionMark.removeClass('question-mark-required');

    var $tooltip = $questionMark.find('.tooltip .tooltip-inner');
    if ($tooltip.length > 0) {
      $tooltip.html(error);
    }
  },
  highlight: function (element, errorClass, validClass) {
    if (!$(element).hasClass(errorClass)) {
      $(element).addClass(errorClass);
      if ($(element).hasClass(validClass)) {
        $(element).removeClass(validClass);
      }
      var value = $(element).val(),
        required = $(element).data('rule-required');
      if (required && (value == null || value == '')) {
        $(element).parent().append('<label class="error-label red" for="' + $(element).prop('id') + '">Povinný údaj</label>');
      }
    }
  },
  unhighlight: function (element, errorClass, validClass) {
    $(element).removeClass('has-error has-success');
    if ($(element).data('rule-required')) {
      $(element).parent().find('.error-label').remove();
    }
    if ($(element).val() != null && $(element).val() != '') {
      $(element).addClass('has-success');
    }
  },
  onfocusout: function (element) {
    $(element).valid();
  },
  onkeyup: false
});

$.validator.addMethod('regex', function (value, element, param) {
  let regex = new RegExp(param);

  return value == '' || regex.test(value);
});

$.validator.addMethod("ajax", function (value, element, param) {
  if (this.optional(element)) {
    return "dependency-mismatch";
  }

  let method = typeof method === "string" && method || "remote";

  var previous = this.previousValue(element, method),
    validator, data, optionDataString;

  if (!this.settings.messages[element.name]) {
    this.settings.messages[element.name] = {};
  }
  previous.originalMessage = previous.originalMessage || this.settings.messages[element.name][method];
  this.settings.messages[element.name][method] = previous.message;

  param = typeof param === "string" && { url: param } || param;
  optionDataString = $.param($.extend({ data: value }, param.data));
  if (previous.old === optionDataString) {
    return previous.valid;
  }

  previous.old = optionDataString;
  validator = this;
  this.startRequest(element);
  data = {};
  data[element.name] = value;

  $.ajax($.extend(true, {
    mode: "abort",
    dataType: "json",
    data: data,
    success: function (response) {
      var valid = response === true || response === "true",
        errors, message, submitted;

      validator.settings.messages[element.name][method] = previous.originalMessage;
      if (valid) {
        submitted = validator.formSubmitted;
        validator.resetInternals();
        validator.toHide = validator.errorsFor(element);
        validator.formSubmitted = submitted;
        validator.successList.push(element);
        validator.invalid[element.name] = false;
        validator.showErrors();
      } else {
        errors = {};
        message = response || validator.defaultMessage(element, { method: method, parameters: value });
        errors[element.name] = previous.message = message;
        validator.invalid[element.name] = true;
        validator.showErrors(errors);
      }
      previous.valid = valid;
      validator.stopRequest(element, valid);
    },
    error: function () {
      console.error('Unable to perform validation.')
    }
  }, param));
  return "pending";
});

window.ePortalInitValidations = function ($form, options) {

  if (!$form) {
    return;
  }

  var addQuestionMark = function (input, questionMark) {
    if (input.is(':checkbox') || input.is(':radio')) {
      input.parent().append(questionMark);
    } else {
      input.after(questionMark);

    }
  };

  /**
   * Creates tootlip message from data attribute
   */
  var createTooltipMsg = function (input) {
    var message = input.data('hint');

    if (message != null && message != "" && !input.hasClass('has-tooltip')) {
      input.addClass('has-tooltip');
      var questionMark = $('<div class="question-mark question-mark-input"> <div class="tooltip top"> <div class="tooltip-arrow" ></div> <div class="tooltip-inner"> <p>' + message + '</p> </div> </div> </div>');
      addQuestionMark(input, questionMark);
      ePortalTooltip.registerTooltip($questionMark);
    }
  };

  var initTooltip = function (input) {
    if (!input.hasClass('has-tooltip')) { // prevent adding tooltip multiple times
      input.addClass('has-tooltip');
      var questionMark = $('<div class="question-mark question-mark-required question-mark-input"><div class="tooltip top"> <div' +
        ' class="tooltip-arrow" ></div> <div class="tooltip-inner"></div> </div> </div>');
      addQuestionMark(input, questionMark);
    }
  };

  // create tootlip message for every input hint
  $form.find('[data-hint]').each(function (index, el) {
    createTooltipMsg($(el));
  });

  // init tooltip if it is not created in hint phase
  $form.find(':dataStartsWith(rule)').each(function (index, el) {
    initTooltip($(el));
  });

  $form.validate(options);
}

window.ePortalShowSpinner = function (element) {
  var el = '#o2-spinner';

  if (typeof element !== 'undefined') {
    el = element;
  }

  $(el).show();
}

window.ePortalHideSpinner = function (element) {
  var el = '#o2-spinner';

  if (typeof element !== 'undefined') {
    el = element;
  }

  $(el).hide();
}

window.ePortalInitDropdownFormValidations = function ($form, spinnerElement) {
  var init = function ($form) {
    ePortalInitValidations($form, {
      submitHandler: function (form) {
        window.ePortalShowSpinner(spinnerElement);
        form.submit();
      },
      invalidHandler: function (event, valitdator) {
        window.ePortalHideSpinner(spinnerElement);
      },
      errorPlacement: function (error, element) {
        $(error).removeAttr('class').removeAttr('id');

        var value = $(element).val(),
          required = $(element).data('rule-required');
        var $questionMark = $(element).siblings('.question-mark');

        if (required && (value == null || value == '')) {
          $(element).removeClass('tooltip-visible');
          $questionMark.addClass('question-mark-required');
        }

        $(element).addClass('tooltip-visible');
        $questionMark.removeClass('question-mark-required');

        var $tooltip = $questionMark.find('.tooltip .tooltip-inner');
        if ($tooltip.length > 0) {
          $tooltip.html(error);
        }
      },
      ignore: function () {
        if (window.isCompanyOwner) {
          return " .ignore, .tt-hint";
        }
        else {
          return ":hidden, .ignore, .tt-hint";
        }
      }()

    });
  };
  return { init: init };
}

window.ePortalInitIsDropdownFormValid = function (isValid, spinnerElement) {
  if (!isValid) {
    window.ePortalHideSpinner(spinnerElement);
    var title = $(validatorResult.currentForm).find(".dropdown-title")
    var boxContent = $(validatorResult.currentForm).find(".box-content");

    if ($(boxContent).css("display") === 'none') {
      title.trigger('click');
    }

    return false;
  } else {
    return true;
  }
}

/**
 * Validates the field for a SVK phone number format.
 *
 * @return Accepts following formats:
 *  +4219NNNNNNNN,
 * 004219NNNNNNNN,
 *     09NNNNNNNN,
 *      9NNNNNNNN
 */
$.validator.addMethod("msisdn_format", function (value, element) {

  // custom behavior for recharging
  if ((document.getElementsByName("selectMsisdn")).length != 0) {

    if (document.getElementById("rechargingMsisdn") != null) {

      if (document.getElementById("rechargingMsisdn").value != "-9") {
        return true;
      }
    }
  }
  value = stripMsisdnWhitespace(value);
  return this.optional(element) || value.match(/^((\+|00)4219|09|9)\d{8}$/);
}, "Wrong phone number format.");

/**
 * Validates the field for a Tesco club card format.
 *
 * @return Accepts any string representing Tesco club card number.
 */
$.validator.addMethod("tesco_club_card_format", function (value, element) {
  if (this.optional(element)) {
    return true;
  }
  if (!value.match(/^\d{18}$/)) {
    return false;
  }

  var i;
  var clubCardLength = 18;

  var clubCard = new Array(clubCardLength);
  for (i = 0; i < clubCardLength; i++) {
    clubCard[i] = parseInt(value.substring(i, i + 1));
  }
  var multiple = [2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];

  var result1 = new Array(clubCardLength - 1);
  for (i = 0; i < clubCardLength - 1; i++) {
    result1[i] = truncate((clubCard[i] * multiple[i]) / 10.0);
  }

  var result2 = new Array(clubCardLength - 1);
  for (i = 0; i < clubCardLength - 1; i++) {
    result2[i] = Math.round((((clubCard[i] * multiple[i]) / 10.0)
      - (truncate((clubCard[i] * multiple[i]) / 10.0))) * 10.0);
  }

  var result12 = new Array(clubCardLength - 1);
  for (i = 0; i < clubCardLength - 1; i++) {
    result12[i] = result1[i] + result2[i];
  }

  var sum = 0;
  for (i = 0; i < clubCardLength - 1; i++) {
    sum += result12[i];
  }
  var finalResult = Math.round(10 - (((sum / 10.0) - (truncate(sum / 10.0)))
    * 10.0));

  if (finalResult > 9) {
    finalResult = finalResult % 10;
  }
  if (finalResult != clubCard[clubCardLength - 1]) {
    return false;
  }
  return true;

}, "Wrong Tesco club card number format.");

/**
 * Validates the field for a czech or slovak msisdn format.
 *
 * @return Accepts any string containing 9-10 numbers
 */
$.validator.addMethod("msisdn_format_czsk", function (value, element) {
  value = stripMsisdnWhitespace(value);
  return this.optional(element) || value.match(/^\d{9,10}$/);
}, "Wrong phone number format.");

/**
 * Validates the field for a slovak national msisdn format.
 *
 * @return Accepts any string containing 9-10 numbers
 */
$.validator.addMethod("msisdn_format_sk_national", function (value, element) {
  value = stripMsisdnWhitespace(value);
  return this.optional(element) || value.match(/^0?9\d{8}$/);
}, "Wrong phone number format.");

/**
 * Validates the field for an eu msisdn format.
 *
 * @return Accepts any string containing 9-10 numbers or 12 numbers beginning with +
 */
$.validator.addMethod("msisdn_format_eu", function (value, element) {
  value = stripMsisdnWhitespace(value);
  return this.optional(element) || value.match(/^\d{9,10}$/) || value.match(
    /^(\+|00)\d{12}$/);
}, "Wrong phone number format.");

/**
 * Checks if at least one of the fields passed as arguments is not empty
 */
$.validator.addMethod("required_one_of", function (value, element, fields) {

  if (null != value && value.length > 0) {
    return true;
  }

  if (null == fields || fields.length == 0) {
    return false;
  }

  for (var propertyName in fields) {

    if ($("[name='" + fields[propertyName] + "']").val().length > 0) {
      return true;
    }
  }

  return false;
}, "At least one of the required fields must be filled.");

/**
 * Validates smart id format
 *
 * @return accepts strings of 4 digits
 */
$.validator.addMethod("smartid_format", function (value, element) {
  return this.optional(element) || value.match(/^\d{4}$/);
}, "Wrong Smart Id format.");

/**
 * Validates email format
 *
 * @return accepts strings matching the following regexp [a-z0-9\-_\.]++@[a-z0-9\-_]++(\.[a-z0-9\-_]++)++
 */
$.validator.addMethod("email_format", function (value, element) {
  return this.optional(element) ||
    value.match(
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);
}, "Wrong email format.");

/**
 * Validates user password
 *
 * @return accpets any string not containing a space ' ' between 6 and 50 characters long
 */
$.validator.addMethod("password_format", function (value, element) {
  return this.optional(element) || value.match(/^[^ ]{6,50}$/);
}, "Wrong password format.");

/**
 * checks whether the retyped password is same as the value of the first argument
 */
$.validator.addMethod("password_retype", function (value, element, args) {
  if (this.optional(element)) {
    return true;
  }

  if (null == args || args.length < 1) {
    return false;
  }

  var compareTo = "[name='" + args[1] + "']";

  return value == $(compareTo).val();
}, "Password and password retype are not equal.");

/**
 * Tests the given login string against a pattern
 * Conditions:
 * - length is within closed interval from 3 to 30 characters
 * - contains only alfanumeric characters and special characters '_', '-', '.' (especially no spaces)
 * - there is one alfanumeric character at least
 * - there are no two special characters side-by-side
 */
$.validator.addMethod("login_format", function (value, element) {
  if (this.optional(element)) {
    return true;
  }

  if (value.length < 3 || value.length > 30) {

    return false;
  }

  if (!value.match(/^([a-zA-Z0-9_\-.])*[a-zA-Z]+([a-zA-Z0-9_\-.])*$/)) {

    return false;
  }

  if (value.match(/"[_\-.]{2}"/)) {

    return false;
  }

  return true;
}, "Wrong login format.");

/**
 * Validates if the string is a valid SIM card serial number
 */
$.validator.addMethod("dic_format", function (value, element) {
  return this.optional(element) || value.match(/^[0-9]{10}$/);
}, "Wrong DIC format.");

/**
 * Validates if the string is a valid SIM card serial number
 */
$.validator.addMethod("sim_format", function (value, element) {
  return this.optional(element) || value.match(/^\d{18}$/);
}, "Wrong SIM card format.");

/**
 * Validates if the string is a valid PUK code
 */
$.validator.addMethod("puk_format", function (value, element) {
  return this.optional(element) || value.match(/^\d{8}$/);
}, "Wrong PUK code format.");

/**
 * Validates if the string is a valid ID card
 */
$.validator.addMethod("idcard_format", function (value, element) {
  return this.optional(element) || value.match(/^[A-Z]{2} ?\d{6}$/i);
}, "Wrong ID card format.");

/**
 * Validates if the string is a valid passport id
 */
$.validator.addMethod("passport_format", function (value, element) {
  return this.optional(element) || value.match(
    /^(([0-9]{7})|([a-zA-Z]{1}[0-9]{7})|([a-zA-Z]{2}[0-9]{7}))$/i);
}, "Wrong passport id format.");

/**
 * Validates if the string is a valid diplomatic passport id
 */
$.validator.addMethod("diplomatic_passport_format", function (value, element) {
  return this.optional(element) || value.match(/^[A-Za-z0-9\\.\\-]{1,10}$/i);
}, "Wrong diplomatic passport id format.");

/**
 * Validates if the string is a valid passport id
 */
$.validator.addMethod("passport2_format", function (value, element) {
  return this.optional(element) || value.match(
    /^[a-zA-Z0-9-]{0,20}$/);
}, "Wrong passport id format.");

/**
 * Validates if the string is a ZIP code
 */
$.validator.addMethod("zip_format", function (value, element) {
  return this.optional(element) || value.match(
    /^([0-9]{5})|([0-9]{3} [0-9]{2})$/);
}, "Wrong ZIP code format.");

/**
 * Validates if the string is a valid birth number
 *
 * DEPRECATED
 */
$.validator.addMethod("birth_number_format", function (value, element) {

  var isValid = false;

  //2digits year, (follows with number starts with number from 0156, one digit) - month ,2digit day,3-4 digits for serial number or ser number + checksum
  var matches = value.replace('/', '').match(
    /^(\d{2})([0156]\d)(\d{2})(\d{3,4})$/);

  if (matches) {
    if ((parseInt(matches[1]) < 54 && matches[4].length == 3)
      || (parseInt(matches[1] + matches[2] + matches[3] + matches[4]) % 11
        == 0)) {
      isValid = true;
    }
  }

  return isValid;
}, "Zadané rodné číslo nie je v správnom formáte.");

/**
 * Validates if the string is a valid birth number
 */
$.validator.addMethod("birth-number", function (value, element) {
  var isValid = false;

  // 2 digits year, (follows with number starts with number from 0156, one digit) - month , 2 digit day, 3-4 digits for serial number or ser number + checksum
  var matches = value.replace('/', '').match(
    /^(\d{2})([0156]\d)(\d{2})(\d{3,4})$/);

  if (matches) {
    if ((parseInt(matches[1]) < 54 && matches[4].length == 3)
      || (parseInt(matches[1] + matches[2] + matches[3] + matches[4]) % 11
        == 0)) {
      isValid = true;
    }
  }

  return isValid;
}, "Zadané rodné číslo nie je v správnom formáte.");

/**
 * Validates birth number format for foreigners
 *
 */
$.validator.addMethod("birth_number_foreigners", function (value) {
  return value.replace('/', '')
    .match(/^((\d{2}([05][1-9]|[16][0-2])(0[1-9]|[1-2]\d|3[01])\/?\d{3}[\dA]?)|(C\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[01])\d{2}))$/i);
}, "Zadané rodné číslo nie je v správnom formáte.");

/**
 * Validates the input using external javascript method that takes value and element as an input values returns true if the specified field is valid, false otherwise.
 *
 */
$.validator.addMethod("external_validation",
  function (value, element, args) {
    return window[args[1]](element, value);

  }, "External field validation has failed.");

/**
 * Validates if the string pass specified number format
 */
$.validator.addMethod("credit_format_da", function (value, element, args) {

  return this.optional(element) || (value.match(/^([\d.,]+)$/)
    && (value.length > 0)
    && (parseFloat(value) >= parseFloat(args[1]))
    && (parseFloat(value) <= parseFloat(args[2]))
    && (value.indexOf(".") == -1)
    && (value.indexOf(",") == -1)

  );
}, "Wrong da credit number format.");

/**
 * Validates if the string pass specified number format
 */
$.validator.addMethod("credit_format_sms_da", function (value, element, args) {
  if (document.getElementById("rechargingvalue") != null) {
    if (document.getElementById("rechargingvalue").value != "-9"
      && document.getElementById("rechargingvalue").value != "0") {
      return true;
    }
  }
  return this.optional(element) || (value.match(/^([\d.,]+)$/)
    && (value.length > 0)
    && (parseFloat(value) >= parseFloat(args[1]))
    && (parseFloat(value) <= parseFloat(args[2]))
    && (value.indexOf(".") == -1)
    && (value.indexOf(",") == -1)

  );
}, "Wrong sms da credit number format.");

/**
 * Validates if the string pass specified number format
 */
$.validator.addMethod("custom_credit_range", function (value, element, args) {
  return this.optional(element) || (!isNaN(value) && ((value >= args[0])
    && (value <= args[1])));
}, "Wrong sms da credit number format.");

/**
 * Validates if the string pass specified number format
 */
$.validator.addMethod("required_field", function (value, element) {
  return this.optional(element) || (value != "0");
}, "Wrong required format.");

/**
 * Validates if the string pass specified date format
 */
$.validator.addMethod("mnp_date_format", function (value, element) {
  return this.optional(element) || value.match(
    /^(\d{2}[.]{1}\d{2}[.]{1}\d{4})$/);
}, "Wrong mnp date format.");

/**
 * Validates if the string pass date format dd.MM.yyyy
 */
$.validator.addMethod("date_format", function (value, element) {

  if (this.optional(element)) {
    return true;
  }

  var inputText = value;
  var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/;
  // Match the date format through regular expression
  if (inputText.match(dateformat)) {
    var pdate = inputText.split('.');
    var len = pdate.length;
    // Extract the string into month, date and year
    if (len != 3) {
      return false;
    }
    var dd = parseInt(pdate[0]);
    var mm = parseInt(pdate[1]);
    var yy = parseInt(pdate[2]);
    // Create list of days of a month [assume there is no leap year by default]
    var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (mm == 1 || mm > 2) {
      if (dd > ListofDays[mm - 1]) {
        return false;
      }
    }
    if (mm == 2) {
      var lyear = false;
      if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
        lyear = true;
      }
      if ((lyear == false) && (dd >= 29)) {
        return false;
      }
      if ((lyear == true) && (dd > 29)) {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
}, "Wrong date format.");

/**
 * Validates if the string pass specified date after today
 */
$.validator.addMethod("date_is_after_today", function (value, element) {
  var result1 = this.optional(element);
  var result2 = false;

  var arrDate = value.split(".");
  var useDate = new Date(arrDate[2], arrDate[1] - 1, arrDate[0]);
  var today = new Date();
  if (useDate > today) {
    result2 = true;
  }

  return result1 || result2;
}, "Date is not after today.");

/**
 * Validates if the string pass specified date is today or after today
 */
$.validator.addMethod("date_is_today_or_after_today",
  function (value, element) {
    var result1 = this.optional(element);
    var result2 = false;

    var arrDate = value.split(".");
    var useDate = new Date(arrDate[2], arrDate[1] - 1, arrDate[0], 23, 59,
      59);
    var today = new Date();
    if (useDate >= today) {
      result2 = true;
    }

    return result1 || result2;
  }, "Date is not today nor after today.");

/**
 * Validates if the string pass specified number format
 */
$.validator.addMethod("selectValueDa", function (value, element) {

  if (element.value == "0") {
    return false;
  }

  return true;


}, "Wrong selected value.");

/**
 * Validates if the string is a valid
 */
$.validator.addMethod("custom_format", function (value, element, args) {
  if (null == args || args.length < 1) {
    return false;
  }
  else {
    var compareTo = new RegExp(args[1]);
    return this.optional(element) || value.match(compareTo);
  }
}, "Wrong format.");

/**
 * Validates if the string is of specified length
 */
$.validator.addMethod("input_length_short", function (value, element, args) {
  var inputLength = 255;
  return this.optional(element) || (value.length <= inputLength);

}, "Wrong input length.");

$.validator.addMethod("input_length_company_name",
  function (value, element, args) {
    var inputLength = 100;
    return this.optional(element) || (value.length <= inputLength);

  }, "Wrong input length.");

$.validator.addMethod("valid_age_from_birthbumber", function (element, value) {
  value = normalizePersonalId(value);
  var year = getYear(value);
  var month = getMonth(value);
  var day = getDay(value);
  var date = new Date(year, month - 1, day);

  return (date < getThresholdDate());
}, "Wrong age");

function getThresholdDate() {
  var date = new Date();
  date.setFullYear(date.getFullYear() - 15);
  return date;
}

/**
 * Validates if the string is of specified length
 */
$.validator.addMethod("input_length", function (value, element, args) {
  if (null == args || args.length < 1) {
    return false;
  }
  else {
    var inputLength = parseInt(args[1]);
    if (isNaN(inputLength)) {
      inputLength = args;
    }
    return this.optional(element) || (value.length <= inputLength);
  }
}, "Wrong input length.");

/**
 * Validates verification code format
 *
 * @return accepts strings of 6 letters
 */
$.validator.addMethod("vcode_format", function (value, element) {

  if (this.optional(element)) {
    return true;
  }

  if (value.length == 6) {

    return value.match(/^[a-zA-Z0-9]+$/)
  }
  else {
    return false;
  }
}, "Wrong verification code format.");

/**
 * Validates if the string pass specified date format
 */
$.validator.addMethod("street_town_format", function (value, element) {
  return this.optional(element) || value.match(/^([-a-zA-Z0-9á-žÁ-Ž.\s])*$/);
}, "Wrong street or town format.");

/**
 * Validates if the string pass specified time format
 */
$.validator.addMethod("time_format", function (value, element) {
  return this.optional(element) || value.match(
    /^(20|21|22|23|[01]\d|\d)(([:.][0-5]\d){1,2})$/);
}, "Wrong time format.");

/**
 * Validates if element is not empty only if asterisk is set
 */
$.validator.addMethod("required_asterisk", function (value, element) {

  var name = element.getAttribute("name");
  var required = $("input[name=" + name + "]").parent().parent().hasClass(
    "required");
  return !required || required && value.length > 0;
}, "Wrong required asterisk format.");

/**
 * Customer number validation
 **/
$.validator.addMethod("curefno_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(/^\d{8,9}$/);
  }
  return valid;
}, "Wrong customer number format.");

/**
 * ICO number validation
 **/
$.validator.addMethod("ico_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(/^(\d{2})?\d{6}$/);
  }
  return valid;
}, "Wrong ICO format");


/**
 * ICO number validation non required
 **/
$.validator.addMethod("ico_format_non_required", function (value, element) {
  var valid = true;
  if (value) {
    valid = value.trim().match(/^(\d{2})?\d{6}$/);
  }
  return valid;
}, "Wrong ICO format");


/**
 * Permanent residence id number validation
 **/
$.validator.addMethod("residenceid_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(/^[A-Z]{2}\d{7}$/);
  }
  return valid;
}, "Wrong residence number format");

/**
 * Permanent residence id number validation
 **/
$.validator.addMethod("residenceid2_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(/^[a-zA-Z0-9-]*$/);
  }
  return valid;
}, "Wrong residence number format");

/**
 *
 * Permanent residence id number validation
 **/
$.validator.addMethod("store_assistant_name_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(
      /^[^\d\t\r\n\v\f!"#$%&()*+/:;<=>?@\[\\\]_{|}~]{2,30}$/);
  }
  return valid;
}, "Wrong store assistant name format");

/**
 * Permanent residence id number validation
 **/
$.validator.addMethod("store_assistant_id_format",
  function (value, element) {
    var valid = false;
    if (value) {
      valid = value.trim().match(/^\w{6,16}$/);
    }
    return valid;
  }, "Wrong store assistant ID format");

function truncate(n) {
  return Math[n > 0 ? "floor" : "ceil"](n);
}

$.validator.addMethod("card_type", function (value, element) {
  var type = $('#prepaidRegistrationIdCardTypeInput option:selected').val()
  var valid = false;

  switch (type) {
    case "ID_CARD":
      return this.optional(element) || value.match(/^([A-Z]{2}\d{6}|\d{6}[A-Z]{2})$/i);
      break;
    case "PASSPORT":
      return this.optional(element) || value.match(
        /^(([0-9]{7})|([a-zA-Z]{1}[0-9]{7})|([a-zA-Z]{2}[0-9]{7}))$/i);
      break;
    case "ID_CARD_EU":
      return this.optional(element) || value.match(/^[A-Z]{2} ?\d{6}$/i);
      break;
    case "DIPLOMATIC_PASSPORT":
      return this.optional(element) || value.match(
        /^[A-Za-z0-9\\.\\-]{1,10}$/i);
      break;
    default:
      return false;
  }

  return valid;
}, "Wrong card ID");

$.validator.addMethod("card_type_statutory", function (value, element) {
  var statutory = $(element).data("card-type-input");
  var type = $('#' + statutory + ' option:selected').val()
  var valid = false;

  switch (type) {
    case "ID_CARD":
      return this.optional(element) || value.match(/^[A-Z]{2} ?\d{6}$/i);
      break;
    case "PASSPORT":
      return this.optional(element) || value.match(
        /^(([0-9]{7})|([a-zA-Z]{1}[0-9]{7})|([a-zA-Z]{2}[0-9]{7}))$/i);
      break;
    case "ID_CARD_EU":
      return this.optional(element) || value.match(/^[A-Z]{2} ?\d{6}$/i);
      break;
    case "DIPLOMATIC_PASSPORT":
      return this.optional(element) || value.match(
        /^[A-Za-z0-9\\.\\-]{1,10}$/i);
      break;
    default:
      return false;
  }

  return valid;
}, "Wrong card ID");

/**
 * Date range validation
 */
jQuery.validator.addMethod("greater_or_equal_than_date",
  function (value, element, args) {

    var format = 'dd.m.yy';
    var dateFrom = $.datepicker.parseDate(format, $(args).val());
    var dateTo = $.datepicker.parseDate(format, value);

    if (!dateFrom) {
      return true;
    }

    if (!/Invalid|NaN/.test(dateTo)) {
      return dateTo >= dateFrom;
    }

    return isNaN(value) && isNaN($(args).val())
      || (Number(value) >= Number($(args).val()));
  }, 'Must be greater than {0}.');

/**
 * Date range validation
 */
jQuery.validator.addMethod("lower_or_equal_than_date",
  function (value, element, args) {

    var format = 'dd.m.yy';
    var dateFrom = $.datepicker.parseDate(format, value);
    var dateTo = $.datepicker.parseDate(format, $(args).val());

    if (!dateTo) {
      return true;
    }

    if (!/Invalid|NaN/.test(dateFrom)) {
      return dateTo >= dateFrom;
    }

    return isNaN(value) && isNaN($(args).val())
      || Number($(args).val()) >= (Number(value));
  }, 'Must be lower than {0}.');

/**
 * Siebel claim id validation
 */
jQuery.validator.addMethod("siebel_claim_id_format", function (value, element) {
  value = $.trim(value);
  return this.optional(element) || value.match(/^1\-\d+$/);
}, "Wrong siebel id format.");

jQuery.validator.addMethod("bacurefno_format", function (value, element) {
  var valid = false;
  if (value) {
    valid = value.trim().match(/^\d+$/);
  }
  return valid;
}, "Wrong BaCuRefNo format");

// IBAN validation
$.validator.addMethod("iban", function (value, element) {

  // Some quick simple tests to prevent needless work
  if (this.optional(element)) {
    return true;
  }

  // Remove spaces and to upper case
  var iban = value.replace(/ /g, "").toUpperCase(),
    ibancheckdigits = "",
    leadingZeroes = true,
    cRest = "",
    cOperator = "",
    countrycode, ibancheck, charAt, cChar, bbanpattern, bbancountrypatterns, ibanregexp, i, p;

  // Check for IBAN code length.
  // It contains:
  // country code ISO 3166-1 - two letters,
  // two check digits,
  // Basic Bank Account Number (BBAN) - up to 30 chars
  var minimalIBANlength = 5;
  if (iban.length < minimalIBANlength) {
    return false;
  }

  // Check the country code and find the country specific format
  countrycode = iban.substring(0, 2);
  bbancountrypatterns = {
    "AL": "\\d{8}[\\dA-Z]{16}",
    "AD": "\\d{8}[\\dA-Z]{12}",
    "AT": "\\d{16}",
    "AZ": "[\\dA-Z]{4}\\d{20}",
    "BE": "\\d{12}",
    "BH": "[A-Z]{4}[\\dA-Z]{14}",
    "BA": "\\d{16}",
    "BR": "\\d{23}[A-Z][\\dA-Z]",
    "BG": "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
    "CR": "\\d{17}",
    "HR": "\\d{17}",
    "CY": "\\d{8}[\\dA-Z]{16}",
    "CZ": "\\d{20}",
    "DK": "\\d{14}",
    "DO": "[A-Z]{4}\\d{20}",
    "EE": "\\d{16}",
    "FO": "\\d{14}",
    "FI": "\\d{14}",
    "FR": "\\d{10}[\\dA-Z]{11}\\d{2}",
    "GE": "[\\dA-Z]{2}\\d{16}",
    "DE": "\\d{18}",
    "GI": "[A-Z]{4}[\\dA-Z]{15}",
    "GR": "\\d{7}[\\dA-Z]{16}",
    "GL": "\\d{14}",
    "GT": "[\\dA-Z]{4}[\\dA-Z]{20}",
    "HU": "\\d{24}",
    "IS": "\\d{22}",
    "IE": "[\\dA-Z]{4}\\d{14}",
    "IL": "\\d{19}",
    "IT": "[A-Z]\\d{10}[\\dA-Z]{12}",
    "KZ": "\\d{3}[\\dA-Z]{13}",
    "KW": "[A-Z]{4}[\\dA-Z]{22}",
    "LV": "[A-Z]{4}[\\dA-Z]{13}",
    "LB": "\\d{4}[\\dA-Z]{20}",
    "LI": "\\d{5}[\\dA-Z]{12}",
    "LT": "\\d{16}",
    "LU": "\\d{3}[\\dA-Z]{13}",
    "MK": "\\d{3}[\\dA-Z]{10}\\d{2}",
    "MT": "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
    "MR": "\\d{23}",
    "MU": "[A-Z]{4}\\d{19}[A-Z]{3}",
    "MC": "\\d{10}[\\dA-Z]{11}\\d{2}",
    "MD": "[\\dA-Z]{2}\\d{18}",
    "ME": "\\d{18}",
    "NL": "[A-Z]{4}\\d{10}",
    "NO": "\\d{11}",
    "PK": "[\\dA-Z]{4}\\d{16}",
    "PS": "[\\dA-Z]{4}\\d{21}",
    "PL": "\\d{24}",
    "PT": "\\d{21}",
    "RO": "[A-Z]{4}[\\dA-Z]{16}",
    "SM": "[A-Z]\\d{10}[\\dA-Z]{12}",
    "SA": "\\d{2}[\\dA-Z]{18}",
    "RS": "\\d{18}",
    "SK": "\\d{20}",
    "SI": "\\d{15}",
    "ES": "\\d{20}",
    "SE": "\\d{20}",
    "CH": "\\d{5}[\\dA-Z]{12}",
    "TN": "\\d{20}",
    "TR": "\\d{5}[\\dA-Z]{17}",
    "AE": "\\d{3}\\d{16}",
    "GB": "[A-Z]{4}\\d{14}",
    "VG": "[\\dA-Z]{4}\\d{16}"
  };

  bbanpattern = bbancountrypatterns[countrycode];

  // As new countries will start using IBAN in the
  // future, we only check if the countrycode is known.
  // This prevents false negatives, while almost all
  // false positives introduced by this, will be caught
  // by the checksum validation below anyway.
  // Strict checking should return FALSE for unknown
  // countries.
  if (typeof bbanpattern !== "undefined") {
    ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "");
    if (!(ibanregexp.test(iban))) {
      return false; // Invalid country specific format
    }
  }

  // Now check the checksum, first convert to digits
  ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
  for (i = 0; i < ibancheck.length; i++) {
    charAt = ibancheck.charAt(i);
    if (charAt !== "0") {
      leadingZeroes = false;
    }
    if (!leadingZeroes) {
      ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt);
    }
  }

  // Calculate the result of: ibancheckdigits % 97
  for (p = 0; p < ibancheckdigits.length; p++) {
    cChar = ibancheckdigits.charAt(p);
    cOperator = "" + cRest + "" + cChar;
    cRest = cOperator % 97;
  }
  return cRest === 1;
}, "Zadajte IBAN v platnom formáte.");

$.validator.addMethod("first_name", function(value, element) {
  value = $.trim(value);
  var re = XRegExp2("^([\\p{L}])([\\s\\p{L}]{0,48})([\\p{L}])$");
  return this.optional(element) || re.test(value);
}, "Wrong first name format.");

$.validator.addMethod("last_name", function(value, element) {
  value = $.trim(value);
  var re = XRegExp2("^([\\p{L}])([\\s\\p{L}-]{0,48})([\\p{L}])$");
  return this.optional(element) || re.test(value);
}, "Wrong last name format.");

$.validator.addMethod("icoChecksum", function(value, element) {
  value = $.trim(value);
  return this.optional(element) || icoChecksum(value);
});

function icoChecksum(ico){
  var $sum = 0;
  var $icoArr = ico.split("");

  for ($i = 0; $i < 7; $i++) {
    $sum += $icoArr[$i] * (8 - $i);
  }
  $sum = $sum % 11;
  $c = 11 - $sum;

  if($sum == 1) $c = 0;
  if($sum == 0) $c = 1;
  if($sum == 10) $c = 1;
  if(parseInt($icoArr[7]) != $c) return false;


  return true;
};

$.validator.addMethod("company_name", function(value, element) {
  return this.optional(element) || /^[^\t\r\n\v\f]{2,100}$/i.test($.trim(value));
}, "Wrong company name format.");

$.validator.addMethod("msisdn_sk_format", function (value, element) {
  value = $.trim(value);
  var re = XRegExp2("^(\\+421|00421|0){0,1}[9]{1}[0-9]{8}$");
  return this.optional(element) || re.test(value);
}, "Wrong msisdn sk format.");

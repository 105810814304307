(function ($) {
  $(document).ready(function () {

    window.ePortalInitDropdownBox($('.dropdown-box'));
    window.ePortalInitPrependedInput();

    $('.dropdown-text-box > a').click(function () {
      $(this).parent().toggleClass('expanded');
      $(this).parent().children('.text-box-content').slideToggle(400);
      return false;
    });

    $('.detail-fivestar-elements').each(function () {
      var _this = $(this);
      var rating = _this.data('rating');
      _this.children('.detail-fivestar-element').each(function () {
        if (rating - 1 >= 0) {
          $(this).addClass('staron');
          rating--;
        } else if (rating - 0.5 >= 0) {
          $(this).addClass('starhalf');
          rating -= 0.5;
        }
      });
    });


    $('.tile-selection-list .tile-item-wrapper').click(function () {
      if (!$(this).hasClass('disabled')) {
        $(this).children('input').click();
      }
    });

    $('.tile-selection-list .tile-item-wrapper input[type=checkbox]').click(function (e) {
      $(this).parent().toggleClass('selected');
      e.stopPropagation();
    });

    $('.tile-selection-list .tile-item-wrapper input[type=radio]').click(function (e) {
      $(this).parents('.tile-selection-list').find('input[type=radio]').each(function () {
        $(this).parent().removeClass('selected');
      });

      $(this).parent().addClass('selected');
      e.stopPropagation();
    });


    $('.btn.dropdown-toggle').hover(
      function () {
        $(this).parent().find('.btn.dropdown-toggle').addClass('hover');
      },
      function () {
        $(this).parent().find('.btn.dropdown-toggle').removeClass('hover');
      }
    );

    var colCount = 0;
    $('table tr:nth-child(1) td').each(function () {
      ($(this).attr('colspan')) ? colCount += +$(this).attr('colspan') : colCount++;
    });

    $('.sort-down').click(function () {
      $(this).toggleClass('sort-down');
      $(this).toggleClass('sort-up');
      $(this).parent().parent().next().find('tr').each(function () {
        $(this).find('td').css('background', 'transparent');
        $(this).find('td:eq(' + i + ')').css('background', '#f0f0f0');
      });
    });

    $('table th.hover').click(function () {
      var i = $(this).index();
      $(this).find('.sort').toggleClass('sort-down');
      $(this).find('.sort').toggleClass('sort-up');
      $('table th.hover').css('background', '#f7f7f7');
      $(this).css('background', '#f0f0f0');
      $(this).parent().parent().next().find('tr').each(function () {
        $(this).find('td').css('background', 'transparent');
        $(this).find('td:eq(' + i + ')').css('background', '#f7f7f7');
      });
    });

    $('table th.hover .sort').click(function () {
      $(this).toggleClass('sort-down');
      $(this).toggleClass('sort-up');
    });

    $('.table .hover').hover(
      function () {
        $(this).find('.tooltip').stop().fadeIn(200);
      },
      function () {
        $(this).find('.tooltip').stop().fadeOut(200);
      }
    );


    var progressBars = $(".progress-bar .fill");

    // check ci mam viditelne nejake progressbary po nacitani stranky
    progressBars.each(function () {
      // if ($(this).is(':in-viewport')) {
      var progressBarValue = $(this).attr('data-value');
      var progressBarMax = $(this).attr('data-max');
      $(this).animate({ 'width': progressBarValue / progressBarMax * 100 + '%' }, 500);
      // }
    });

    $('.btn-popup').each(function () {
      window.ePortalRegisterPopup($(this));
    });

    $('.input-with-action  .small-delete-icon').each(function () {
      $(this).click(function () {
        $(this).siblings('select.selectpicker').selectpicker('deselectAll');
      })
    });

    // backend validation alert - on click slide to input with error
    var multipleErrorsAlert = $('.validations-multiple-errors-anchor');
    var errorReference = multipleErrorsAlert.length > 0 ? multipleErrorsAlert : $('.validations-error-message-row');
    errorReference.click(function (event) {
      var id = $(this).data('o2-target');
      if (!$(event.target).is('a') || multipleErrorsAlert.length > 0) {
        var input;
        if (id != null && id != "") {
          event.preventDefault();
          input = $('[data-o2-id="' + id + '"');

          $('html, body').animate({
            scrollTop: input.offset().top - 20
          }, 400, function () {
            input.focus();
          });
        }
        else {
          event.preventDefault();
          input = $(this).closest('.eportal-portlet').find('input.has-error:first');

          if (input.length > 0) {
            $('html, body').animate({
              scrollTop: input.offset().top - 20
            }, 400, function () {
              input.focus();
            });
          }
        }
      }
    });

    $('.table thead th').each(function () {
      if ($(this).hasClass('hover')) {
        var textH = $(this).find('.text').innerHeight();
        var sortH = $('.sort').innerHeight();
        $(this).find('.sort').css({ 'margin-top': (textH - sortH) / 2 });
      }
    });

    // dropdown table
    $('.dropdown-table.expandable').each(function () {
      ePortalInitExpandableTableRow($(this));
    });

    $('.nav.nav-tabs.accordion-tabs').tabCollapse();

    function adjustBtnTogglHeight() {
      $('.btn-toggle:visible').each(function () {
        var $this = $(this),
          $prev = $this.prev();
        $this.height($prev.height());

        $this.next().css('top', $this.outerHeight() - 2);
      });
    }

    $('body').on('click', '.alert > .close', function () {
      var wrapper = $(this).parent();
      wrapper.addClass('alert--closing');

      setTimeout(function(){
        wrapper.slideUp();
      },300);
    });

    $(window).resize(function () {
      adjustBtnTogglHeight();
    });

    adjustBtnTogglHeight();

    initInput($('input[type=text], input[type=email], input[type=password], textarea, input[type=tel], input[type=url]'));

    // handle toggling drop down menu
    $('.mojeo2-user-head > .logged-in .user-menu, .mojeo2-user-head > .logged-in .user-image.btn-toggle').hover(
      function () {
        $('.mojeo2-user-head > .logged-in .user-image.btn-toggle').addClass('hover');
      },
      function () {
        $('.mojeo2-user-head > .logged-in .user-image.btn-toggle').removeClass('hover');
      }
    );

    $('.detail-fivestar-elements.rateable > .detail-fivestar-element').on('mouseover', function () {
      $(this).addClass('hover');
      $(this).prevAll().addClass('hover');
    });

    $('.detail-fivestar-elements.rateable > .detail-fivestar-element').on('mouseout', function () {
      $(this).removeClass('hover');
      $(this).prevAll().removeClass('hover');
    });

    $('.detail-fivestar-elements.rateable > .detail-fivestar-element').on('click', function () {
      $(this).addClass('staron');
      $(this).removeClass('starhalf');
      $(this).prevAll().addClass('staron');
      $(this).prevAll().removeClass('starhalf');
      $(this).nextAll().removeClass('staron');
      $(this).nextAll().removeClass('starhalf');

      var onStars = $(this).parent().children('.staron').length;
      $(this).parent().attr('data-rating', onStars);
    });

    // readmore check overflow and remove anchod more
    $('.package__description').each(function (index, item) {
      if ($(item)[0].offsetWidth >= $(item)[0].scrollWidth) {
        $(item).removeClass('text-dots');
        $(item).parents('.read-more__container').find('.read-more').remove();
      }
    });
    //read more link - opens all content of paragraph
    $('.read-more').on('click', function (e) {
      e.preventDefault();
      var container = $(this).parents('.read-more__container');

      container.find('.text-dots').removeClass('text-dots');
      container.find('.read-more__description').attr('class', 'col-xs-12');
      container.find('.read-more__anchor').remove();
    });

    //Nested checkboxes
    $('.checkall').on('click', function () {
      $(this).parents('.form-group').has('.checkbox').siblings('.nested-checkboxes').find('input[type=checkbox]').prop('checked', this.checked);
    });

    $('.chkChild').on('click', function () {
      if (!$(this).is('.checkall')) {
        $(this).closest('.form-group').has('.checkbox').find('.checkall').prop('checked', false);
      }
    });

    //Forms required label - after click on it focus to input
    $('.form-horizontal label.error-label').click(function () {
      $(this).closest('input').focus();
    });

    //Readonly Bootstrap select with change link to active select
    $('.bootstrap-select').click(function (e) {
      if ($(this).hasClass('select--readonly')) {
        $(this).find('.selectpicker').each(function () {
          e.stopPropagation();
        });
      }
    });

    //Readonly Bootstrap select with change link to active select
    $('.bootstrap-select').click(function (e) {
      if ($(this).hasClass('select--readonly')) {
        $(this).find('.selectpicker').each(function () {
          e.stopPropagation();
        });
      }
    });

    //remove input-sm link from datatables search filter and number of pages input
    $.extend($.fn.dataTableExt.oStdClasses, {
      "sFilterInput": "form-control",
      "sLengthSelect": "form-control",
      "sLength": "dataTables_length",
    });

    // Image Object-fit hack for IE. Script copies img src and makes CSS background on its parent
    var userAgent, ieReg, ie;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);

    if (ie) {
      /*console.log('je to IE');*/
      $(".has-image-fit").each(function () {
        var $container = $(this),
          imgUrl = $container.find("img").prop("src");
        if (imgUrl) {
          $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
          $container.children("img").hide();
        }
      });
    }

    // Toggling tab content on Toggle-tabs component GL
    if ($(window).width() > 767) {
      $(document).on('click', '.toggle-tabs .nav-tabs > li > a[data-toggle="tab"]', function () {
        var target = $(this).attr('href');
        $('.toggle-tabs .tab-content .tab-pane').not(target).hide();
        $(target).toggle();
        if ($(target).is(':hidden') == true) {
          $(this).closest('li').addClass('hidden-tab-content');
        }
        else {
          $(this).closest('li').removeClass('hidden-tab-content');
        }
      });
    }

    // Disable css animations on pageload
    setTimeout(function(){
      $('body').removeClass('animation-off');
    },1000);

  });

})($);

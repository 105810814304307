/**
 * Used as a common O2 api that should be used as interface for common system data, such as if the page is loaded in black or in blue pages,..
 *
 */
(function ($) {
  window.O2 = window.O2 || {};

  window.O2.Common = window.O2.Common || {
    settings: {
      lrBlueLocation: '',
      lrBlackLocation: '',
      currentApp: ''
    },
    constants: {
      LR_BLACK_APP: 'LR_BLACK',
      LR_BLUE_APP: 'LR_BLUE'
    }
  };

  O2.Common.init = function (settings) {
    O2.Common.settings = $.extend({}, O2.Common.settings, settings)
  };

  window.O2.Common.api = window.O2.Common.api || {};

  O2.Common.api.isLRBlack = function () {
    return O2.Common.constants.LR_BLACK_APP == O2.Common.settings.currentApp;
  };

  O2.Common.api.isLRBlue = function () {
    return O2.Common.constants.LR_BLUE_APP == O2.Common.settings.currentApp;
  };

  O2.Common.api.getLRBlueLocation = function () {
    return O2.Common.settings.lrBlueLocation;
  };

  O2.Common.api.getLRBlackLocation = function () {
    return O2.Common.settings.lrBlackLocation;
  };

  O2.Common.api.xhrCORSRequest = function (url, type, async) {
    return $.ajax({
      url: url,
      type: type,
      async: async !== undefined ? async : true,
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true
    });
  };

})($);

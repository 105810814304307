/* 
 * ===========================================================================
 *  IBA CZ Confidential
 * 
 *  (c) Copyright IBA CZ 2012 ALL RIGHTS RESERVED
 *  The source code for this program is not published or otherwise
 *  divested of its trade secrets.
 * 
 * ===========================================================================
 */
/*
 * This is javascript version of the captcha, detecting bots in the background not 
 * bothering human users with image rewriting. It hides the regular captcha.
 * Prerequisities for using this script:
 * - you have the correct captcha passcode (for the regular captcha in the form)
 * - the DIV container for HTML captcha has class 'captcha'
 * - the input for captcha passcode is the only input in the within the captcha container
 * 
 * Recommendation:
 * - the HTML with regular captcha to be already in the DOM before executing setCaptcha(formName, code)
 * 
 * Usage:
 * - for form "loginForm" with captcha that has a correct passcode "abcd":
 *          setCaptcha("loginForm", "abcd");
 * 
 */

var captchaMap = {};


window.setCaptcha = function (formName, code) {
  var c = captchaMap[formName];
  if (!c) {
    c = new JsCaptcha(formName, code);
    captchaMap[formName] = c;
  } else {
    c.code = code;
  }
}

function JsCaptcha(formName, code) {

  this.code = code;
  this.formName = formName;
  var that = this;
  // session storage 
  var captchaSessionStorage = new SessionStorageO2();

  /**
   * Private function
   * Generate status logging object    
   */
  function logStatusObject(name, description, data) {
    var objectItem = { name: name, description: description, data: data };
    return objectItem;
  }

  this.disableHtmlCaptcha = function (formName) {
    var captchaDiv = $('form[name=' + formName + '] div.captcha');
    captchaDiv.addClass('captcha-hidden');
  }

  this.captchaPassed = function (honeyCheck, formTimeCheck, eventCheck) {
    var logData = { honeyCheck: honeyCheck, formTimeCheck: formTimeCheck, eventCheck: eventCheck };
    var logObject = logStatusObject("captchaPassed function", "Store information about all status checkers.", logData);
    captchaSessionStorage.addDataToStorage(logObject);

    var count = honeyCheck ? 1 : 0;
    count += formTimeCheck ? 1 : 0;
    count += eventCheck ? 1 : 0;
    return count >= 2;
  };

  this.prepairHoney = function ($form) {
    var formLength = $form.children().length;
    var fieldset = '<fieldset class="honey-input"><div class="form-line "><label>Prosím nevypĺňajte</label><input type="text" name="honey" value="" tabindex="-1" /></div></fieldset>';
    var div1 = '<div class="form-line honey-text"><label>Prosím nemeňte ani nevypĺňajte toto pole</label><input type="text" name="honeyChange" value="changethis" tabindex="-1"/></div>';
    var div2 = '<div class="form-line honey-input-off"><label>Prosím nevypĺňajte</label><input type="text" name="honeyoff" value="" tabindex="-1"/></div>';
    $(fieldset).insertAfter($($form.children()[this.getRandomNumberUpto(formLength)]));
    $(div1).insertAfter($($form.children()[this.getRandomNumberUpto(formLength)]));
    $(div2).insertAfter($($form.children()[this.getRandomNumberUpto(formLength)]));
    $(".honey-text").hide();
  };

  this.getRandomNumberUpto = function (number) {
    return Math.floor((Math.random() * 10) % number);
  };

  this.checkHoney = function ($form) {
    var $honey = $form.find('input[name=honey]');
    var $honeyoff = $form.find('input[name=honeyoff]');
    var $honeyChange = $form.find('input[name=honeyChange]');

    var logData = { honey: $honey.val(), honeyoff: $honeyoff.val(), honeyChange: $honeyChange.val() };
    var logObject = logStatusObject("checkHoney function", "Store information about honey input fields.", logData);
    captchaSessionStorage.addDataToStorage(logObject);

    if ($honey.val() === '' && $honeyoff.val() === '' && $honeyChange.val() === 'changethis') {
      return true;
    }
    return false;
  };

  this.prepairFormTimeCheck = function ($form) {
    //if (typeof (Storage) !== "undefined") {
    if (captchaSessionStorage.checkStorage()) {
      sessionStorage.setItem(this.getFormSessionTimeAttribute($form), new Date().getTime());
    }
  };

  this.getFormSessionTimeAttribute = function ($form) {
    return 'timecheck-timestamp-' + $form.attr('name');
  };

  this.checkFormTimeCheck = function ($form) {
    if (captchaSessionStorage.checkStorage()) {
      var formTimestamp = parseInt(sessionStorage.getItem(this.getFormSessionTimeAttribute($form)));
      var currentTimestamp = new Date().getTime();
      var formLength = $form.find('input[type=text]:visible, input[type=checkbox]:visible, input[type=radio]:visible, input[type=password]:visible select:visible').length;
      var timeNeededForForm;
      if (formLength <= 5) {
        timeNeededForForm = Math.sqrt(formLength) * 1000;// + formLength * 100;
      } else {
        timeNeededForForm = 3000;
      }

      return (currentTimestamp >= formTimestamp + timeNeededForForm);
    }
    return true;
  };

  this.prepairEvents = function ($form, callback) {

    $(document).bind('mousemove.captcha', function () {
      callback();
    });
    $(document).bind('keyup.captcha', function () {
      callback();
    });
    $(document).bind('click.captcha', function () {
      callback();
    });
    $form.find('input,select').bind('focus.captcha', function () {
      callback();
    });
  };

  this.unbindEvents = function ($form) {
    $(document).unbind('.captcha');
    $form.find('input,select').unbind('.captcha');
  };

  var captchaDiv = $('form[name=' + formName + '] div.captcha');
  if (captchaDiv.length > 0) {
    that.disableHtmlCaptcha(formName);
  } else {
    $(document).ready(function () {
      that.disableHtmlCaptcha(formName);
    });
  }

  $(document).ready(function () {
    var $form = $('form[name=' + that.formName + ']');

    that.prepairFormTimeCheck($form);
    that.prepairHoney($form);
    var eventCheck;
    that.prepairEvents($form, function () {
      eventCheck = true;
      that.unbindEvents($form);
    });

    $form.submit(function () {

      var honeyCheck = that.checkHoney($(this));
      var logObjectHoneyCheck = logStatusObject("honeyCheck response", "Store information about honeyCheck response.", honeyCheck);
      captchaSessionStorage.addDataToStorage(logObjectHoneyCheck);

      var formTimeCheck = that.checkFormTimeCheck($(this));
      var logObjectFormTimeCheck = logStatusObject("formTimeCheck response", "Store information about formTimeCheck response.", formTimeCheck);
      captchaSessionStorage.addDataToStorage(logObjectFormTimeCheck);
      if (that.captchaPassed(honeyCheck, formTimeCheck, eventCheck)) {
        var captchaInputSelector = 'div.captcha input';
        var captchaInput = $form.find(captchaInputSelector);
        captchaInput.val(that.code);
        var logDataCaptchaPassed = { captchaInput: captchaInputSelector, code: that.code };
        var logObjectCaptchaPassed = logStatusObject("captchaPassed response", "Store information about captchaPassed response.", logDataCaptchaPassed);
        captchaSessionStorage.addDataToStorage(logObjectCaptchaPassed);
      }
    });
  });

}

/**
 * Focuses on a html input with the given name. This Javascript is not bound on document.ready() so it has to be placed
 * <b>after</b> the element has been rendered.
 *
 * @param inputName
 */
window.setFieldFocus = function (inputName) {
  $("input[name='" + inputName + "']").focus();
}

window.setColour = function (element, colour) {
  if (element.style) {
    element.style.backgroundColor = colour;
  }
}

/**
 * Toggles attribute group visibility depending on the value of
 * `profileAttributes[CUSTOMERS_CLIENT_FORM].value` select
 *
 */
window.enableTooltip = function () {
  $().ready(function () {
    // create hint container
    var hintCloud = $("<div></div>");
    hintCloud.attr({ id: "hintCloud" });
    hintCloud.addClass("hintCloud");
    hintCloud.html("<div class='hintImage'><span class='hintCloudText'></span></div>");
    hintCloud.css({ display: "none" });
    $("body").append(hintCloud);

    bindTooltipEvents();
  });
}

window.bindTooltipEvents = function () {
  var hintCloud = $("#hintCloud");
  var pageDiv = $("#page");
  var pageRightBorder = pageDiv.offset().left + pageDiv.width();

  // bind mouseover event
  $('span.hint').unbind("mouseover").mouseover(function () {
    var p = $(this);
    var position = p.offset();
    $('span.hintCloudText').text($(this)[0].title);

    // adjust width to page max width
    var width = 350;
    if (position.left + width > pageRightBorder) width = pageRightBorder - position.left;

    hintCloud.css({
      position: "absolute", zIndex: "2",
      top: position.top, left: position.left, display: "block", width: width
    });
  });

  // hide hint container
  hintCloud.unbind("mouseout").mouseout(function () {
    hintCloud.hide();
  });
}

window.replaceSpacesNbsp = function (val) {
  val = val.substring(6, val.length);
  val = val.replace(/&nbsp;/g, ' ');
  return val;
}

window.GetXmlHttpObject = function () {
  if (window.XMLHttpRequest) {
    // code for IE7+, Firefox, Chrome, Opera, Safari
    return new XMLHttpRequest();
  }
  if (window.ActiveXObject) {
    // code for IE6, IE5
    return new ActiveXObject("Microsoft.XMLHTTP");
  }
  return null;
}

window.disableAllSelects = function () {
  for (var i = 0; i < document.getElementsByTagName("select").length; i++) {
    document.getElementsByTagName("select")[i].style.visibility = "hidden";
  }
}

window.enableAllSelects = function () {
  for (var i = 0; i < document.getElementsByTagName("select").length; i++) {
    document.getElementsByTagName("select")[i].style.visibility = "";
  }
}

/**
 *    tooltip
 */
var timeout = 500;
var closetimer = 0;
var ddmenuitem = 0;

function mopen(id) {
  mcancelclosetime();
  if (ddmenuitem) ddmenuitem.style.visibility = 'hidden';
  ddmenuitem = document.getElementById(id);
  ddmenuitem.style.visibility = 'visible';
}
function mclose() { if (ddmenuitem) ddmenuitem.style.visibility = 'hidden'; }
function mclosetime() { closetimer = window.setTimeout(mclose, timeout); }
function mcancelclosetime() {
  if (closetimer) {
    window.clearTimeout(closetimer);
  }
}
document.onclick = mclose;

window.removeDiacritics = function (str) {
  var changes = [
    { 'base': 'A', 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
    { 'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
    { 'base': 'D', 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
    { 'base': 'E', 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
    { 'base': 'I', 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
    { 'base': 'L', 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
    { 'base': 'N', 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
    { 'base': 'O', 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
    { 'base': 'R', 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
    { 'base': 'S', 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
    { 'base': 'T', 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
    { 'base': 'U', 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
    { 'base': 'Y', 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
    { 'base': 'Z', 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
    { 'base': 'a', 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
    { 'base': 'c', 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
    { 'base': 'd', 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
    { 'base': 'e', 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
    { 'base': 'i', 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
    { 'base': 'l', 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
    { 'base': 'n', 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
    { 'base': 'o', 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
    { 'base': 'r', 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
    { 'base': 's', 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
    { 'base': 't', 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
    { 'base': 'u', 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
    { 'base': 'y', 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
    { 'base': 'z', 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
  ];

  for (var i = 0; i < changes.length; i++) {
    str = str.replace(changes[i].letters, changes[i].base);
  }
  return str;
}

window.getNodeValue = function (obj, tag) {
  return obj.getElementsByTagName(tag)[0].firstChild.nodeValue;
}

window.stripMsisdnWhitespace = function (value) {
  return value.replace(/[\-\s\/\\()\u00A0]/g, "");
}

/**
 *   js for selector view
 * @param errMsg
 */
window.checkSearchSubmit = function (errMsg) {
  if (isCorrectMsisdn()) {
    removeSkaredaHlaska();
    msisdnSubmit();
  } else if (!hasSkaredaHlaska()) {
    addSkaredaHlaska(errMsg);
  }
}

window.isCorrectMsisdn = function () {
  return ($('#sv_msisdn').val().replace(/\s/g, '').match(/^((\+|00)4219|09|9)\d{8}$/));
}

window.hasSkaredaHlaska = function () {
  return ($('#hidablePanel').children('.skaredaHlaska').length != 0);
}

window.addSkaredaHlaska = function (errMsg) {
  $('#hidablePanel').append(errMsg);
  $('#sv_msisdn').addClass('error');
}

window.removeSkaredaHlaska = function () {
  $('.skaredaHlaska').remove();
  $('#sv_msisdn').removeClass('error');
}

window.msisdnSubmit = function () {
  $('.searchMsisdnButton').focus().click();
}

window.bindEvents = function (errMsg) {
  $('#searchBtnPanel').click(function () {
    checkSearchSubmit(errMsg);
  });
  $('#sv_msisdn').keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
      $(this).blur();
      checkSearchSubmit(errMsg);
    }
  });
}

window.autoSwitchBA = function (selectElement) {
  var selectedValue = selectElement.options[selectElement.selectedIndex].value;
  if (selectedValue && !selectedValue.indexOf('http')) {
    //in case of BK verification, change location
    location = selectedValue;
  } else {
    $(selectElement).closest('.selectFilter').find('input[name*=selectBa]').click();
  }
}

window.actLikeSelect = function (isMobileVersion) {
  if (isMobileVersion) {
    $('#comboPanel select').attr('disabled', 'disabled');
  }
  $('#hider').click(function () {
    checkLiWidth();
    $('#hidablePanel').slideToggle('fast');
  });

  $('#hidablePanel li').click(function (e) {
    hidablePanelLiClickFunction($(this).text());
  });

  $(document).click(function (event) {
    if ($(event.target).parents().index($('#hidablePanel')) == -1
      && $(event.target).parents().index($('#hider')) == -1
      && $(event.target).parents().index($('.ui-autocomplete')) == -1
      && $(event.target).attr('id') != 'hider'
      && $(event.target).attr('id') != 'hidablePanel') {
      if ($('#hidablePanel').is(':visible')) {
        $('#sv_msisdn').blur(); $('.autocomplete-div').hide();
        $('#hidablePanel').slideUp();
      }
    }
  })
}

window.hidablePanelLiClickFunction = function (msisdn) {
  $('#comboPanel').closest('form').validate().settings.ignore = "*";
  $('#comboPanel select').removeAttr('disabled');
  if (msisdn.includes('-')) {
    msisdn = msisdn.replace('&nbsp;', '');
    $("#comboPanel option:first-child").val(msisdn.substr(1, 11)); //Fix for TCs with name
    $("#comboPanel option:first-child").html(msisdn);
  } else {
    msisdn = msisdn.replace('&nbsp;', ' ');
    $("#comboPanel option:first-child").val(msisdn);
    $("#comboPanel option:first-child").html(msisdn);
  }

  /* fix for select value update */
  $("#comboPanel option").show();
  $("#comboPanel option").hide();

  $("#hidablePanel").slideToggle();
  $("#hider").width($("#comboPanel select").width() + 20);

  /* submit form */
  $('#selectorPanel input[name*=selectMsisdn]').click();
  $('#hider').unbind('click');
}

window.checkLiWidth = function () {
  var liWidthMin = 150;
  var liWidthMax = 260; /* 13px*20em*/
  var liWidthCurrent;
  $("#lv_msisdn_list li").each(function (i) {
    if (!$(this).hasClass('NoListItem')) { liWidthCurrent = 5 * ($(this).html().length) + 30; }
    else { liWidthCurrent = 170; }
    if (liWidthMin < liWidthCurrent) { liWidthMin = liWidthCurrent; }
    if (liWidthMin > liWidthMax) { liWidthMin = liWidthMax; }
  });
  $("#hidablePanel").width(liWidthMin);
  $(".searchPanel input[name='hintMsisdn']").width(liWidthMin - 50);
}

/**
 * Depending on the value of the checkbox specified by checkboxName, add (if the checkbox is checked) or remove (if the
 * checkbox is not checked) the css class className from the parent element of the input element specified by
 * targetElementName.
 *
 * @param checkboxName
 * @param targetElementName
 * @param className
 */
window.createStatutoryForm = function () {
  $(document).ready(function () {
    $('input[name="' + checkboxName + '"]').click(function () {
      if (this.checked) {
        $('input[name="' + targetElementName + '"]').parent().addClass(className);
      } else {
        $('input[name="' + targetElementName + '"]').parent().removeClass(className);
      }
    })
  });
}

/**
 * Change value to hidden field selectedBa from selection
 */
window.changeBillingArrangement = function () {
  $(document).ready(function () {
    $("select[name='baList']").change(function () {
      $("input[name='selectedBa']").val(this.options[this.selectedIndex].value);
    })
  });
}

/**
 * Bind onChange event to all checkedFields, on their change if all checkedFields are empty, the note is shown.
 * @param portletId portletId
 * @param formName formName
 * @param checkedFields The list of fields, to which is bind onChange event.
 * @param note The text of the shown note. An element with the "note" class must exist in every parent element for each of the checkedFields.
 */
window.onEmptyFieldsSetNote = function (portletId, formName, checkedFields, note) {
  $(document).ready(function () {
    var form = "#" + portletId + " form[formId=" + formName + "]";
    // bind onChange event on all monitored fields
    var i;
    var selector = "";
    for (i = 0; i < checkedFields.length; i++) {
      selector += form + " :input[name='" + checkedFields[i] + "']";
      if (i < checkedFields.length - 1) selector += ",";
    }
    var elements = $(selector);

    elements.change(function () {
      var fieldsAreEmpty = true;
      elements.each(function () {
        if (fieldsAreEmpty) fieldsAreEmpty = this.value.length == 0;
      });

      if (fieldsAreEmpty) {
        elements.each(function () {
          var noteElem = $(".note", $(this).parent());
          noteElem.html(note);
        });
      }
      else {
        elements.each(function () {
          var noteElem = $(".note", $(this).parent());
          noteElem.html("<!-- IE empty div fix -->");
        });
      }
    });

    // fire the change event on page load
    elements.change();
  });
}

/**
 * Toggle elements visibility according to value of the radio. When the radio with the setted value is checked,
 * the first element is shown and the second element is hidden, and vice versa.
 *
 * @param radioName Radio element name.
 * @param firstElementClassName Class of the element which is shown on
 * @param secondElementClassName
 */
window.toggleElementsOnRadioValue = function (radioName, firstElementClassName, secondElementClassName) {
  $(document).ready(function () {
    $(":input[name='" + radioName + "']").click(function () {
      if (this.checked) {
        if ($(":input[name='" + radioName + "']:checked").val()) {
          $("." + firstElementClassName).show();
          $("." + secondElementClassName).hide();
        }
        else {
          $("." + firstElementClassName).hide();
          $("." + secondElementClassName).show();
        }
      }
    }).triggerHandler("click");
  });
}

window.createRatings = function (_className, _readonly, _name, _value, _granularity) {
  $(document).ready(function () {
    var options = {
      readonly: _readonly,
      name: _name,
      value: _value,
      granularity: _granularity
    };
    $("." + _className).rate(options);
  });
}

window.onCheckedHidePanel = function (portletId, formId, checkBoxNamePrefix, divIdPrefix) {
  $(document).ready(function () {
    var form = $("#" + portletId + " form[formId='" + formId + "']");

    $("input[name|='" + checkBoxNamePrefix + "']", form).change(function () {
      var cbName = $(this).attr("name");
      var index = cbName.substring(cbName.indexOf("[") + 1, cbName.indexOf("]") + 1);

      var panel = $("div#" + divIdPrefix + index + "']", form);
      if (this.checked) {
        panel.hide();
      }
      else {
        panel.show();
      }
    });
  });
}

/**
 * StringBuffer implementation
 */
window.StringBuffer = function () {
  this.buffer = [];
}
StringBuffer.prototype.append = function (string) {
  this.buffer.push(string);
  return this;
};
StringBuffer.prototype.toString = function () {
  return this.buffer.join("");
};

/**
 * A <code>ChoiceFormat</code> allows you to attach a format to a range of numbers.
 * It is generally used in a <code>MessageFormat</code> for handling plurals.
 * The choice is specified with an ascending list of doubles, where each item
 * specifies a half-open interval up to the next item:
 * <blockquote>
 * <pre>
 * X matches j if and only if limit[j] &lt;= X &lt; limit[j+1]
 * </pre>
 * </blockquote>
 * If there is no match, then either the first or last index is used, depending
 * on whether the number (X) is too low or too high.  If the limit array is not
 * in ascending order, the results of formatting will be incorrect.  ChoiceFormat
 * also accepts <code>&#92;u221E</code> as equivalent to infinity(INF).
 *
 * <p>
 * <strong>Note:</strong>
 * <code>ChoiceFormat</code> differs from the other <code>Format</code>
 * classes in that you create a <code>ChoiceFormat</code> object with a
 * constructor (not with a <code>getInstance</code> style factory
 * method). The factory methods aren't necessary because <code>ChoiceFormat</code>
 * doesn't require any complex setup for a given locale. In fact,
 * <code>ChoiceFormat</code> doesn't implement any locale specific behavior.
 *
 * <p>
 * When creating a <code>ChoiceFormat</code>, you must specify an array of formats
 * and an array of limits. The length of these arrays must be the same.
 * For example,
 * <ul>
 * <li>
 *     <em>limits</em> = {1,2,3,4,5,6,7}<br>
 *     <em>formats</em> = {"Sun","Mon","Tue","Wed","Thur","Fri","Sat"}
 * <li>
 *     <em>limits</em> = {0, 1, ChoiceFormat.nextDouble(1)}<br>
 *     <em>formats</em> = {"no files", "one file", "many files"}<br>
 *     (<code>nextDouble</code> can be used to get the next higher double, to
 *     make the half-open interval.)
 * </ul>
 *
 * <p>
 * Here is a simple example that shows formatting and parsing:
 * <blockquote>
 * <pre>
 * double[] limits = {1,2,3,4,5,6,7};
 * String[] monthNames = {"Sun","Mon","Tue","Wed","Thur","Fri","Sat"};
 * ChoiceFormat form = new ChoiceFormat(limits, monthNames);
 * ParsePosition status = new ParsePosition(0);
 * for (double i = 0.0; i &lt;= 8.0; ++i) {
 *     status.setIndex(0);
 *     System.out.println(i + " -&gt; " + form.format(i) + " -&gt; "
 *                              + form.parse(form.format(i),status));
 * }
 * </pre>
 * </blockquote>
 * Here is a more complex example, with a pattern format:
 * <blockquote>
 * <pre>
 * double[] filelimits = {0,1,2};
 * String[] filepart = {"are no files","is one file","are {2} files"};
 * ChoiceFormat fileform = new ChoiceFormat(filelimits, filepart);
 * Format[] testFormats = {fileform, null, NumberFormat.getInstance()};
 * MessageFormat pattform = new MessageFormat("There {0} on {1}");
 * pattform.setFormats(testFormats);
 * Object[] testArgs = {null, "ADisk", null};
 * for (int i = 0; i &lt; 4; ++i) {
 *     testArgs[0] = new Integer(i);
 *     testArgs[2] = testArgs[0];
 *     System.out.println(pattform.format(testArgs));
 * }
 * </pre>
 * </blockquote>
 * <p>
 * Specifying a pattern for ChoiceFormat objects is fairly straightforward.
 * For example:
 * <blockquote>
 * <pre>
 * ChoiceFormat fmt = new ChoiceFormat(
 *      "-1#is negative| 0#is zero or fraction | 1#is one |1.0&lt;is 1+ |2#is two |2&lt;is more than 2.");
 * System.out.println("Formatter Pattern : " + fmt.toPattern());
 *
 * System.out.println("Format with -INF : " + fmt.format(Double.NEGATIVE_INFINITY));
 * System.out.println("Format with -1.0 : " + fmt.format(-1.0));
 * System.out.println("Format with 0 : " + fmt.format(0));
 * System.out.println("Format with 0.9 : " + fmt.format(0.9));
 * System.out.println("Format with 1.0 : " + fmt.format(1));
 * System.out.println("Format with 1.5 : " + fmt.format(1.5));
 * System.out.println("Format with 2 : " + fmt.format(2));
 * System.out.println("Format with 2.1 : " + fmt.format(2.1));
 * System.out.println("Format with NaN : " + fmt.format(Double.NaN));
 * System.out.println("Format with +INF : " + fmt.format(Double.POSITIVE_INFINITY));
 * </pre>
 * </blockquote>
 * And the output result would be like the following:
 * <pre>
 * <blockquote>
 *   Format with -INF : is negative
 *   Format with -1.0 : is negative
 *   Format with 0 : is zero or fraction
 *   Format with 0.9 : is zero or fraction
 *   Format with 1.0 : is one
 *   Format with 1.5 : is 1+
 *   Format with 2 : is two
 *   Format with 2.1 : is more than 2.
 *   Format with NaN : is negative
 *   Format with +INF : is more than 2.
 * </pre>
 * </blockquote>
 *
 * <h4><a name="synchronization">Synchronization</a></h4>
 *
 * <p>
 * Choice formats are not synchronized.
 * It is recommended to create separate format instances for each thread.
 * If multiple threads access a format concurrently, it must be synchronized
 * externally.
 *
 *
 * @see          DecimalFormat
 * @see          MessageFormat
 * @version      1.22 09/21/98
 * @author       Mark Davis
 */
window.ChoiceFormat = function () {
  /**
   * A list of lower bounds for the choices.  The formatter will return
   * <code>choiceFormats[i]</code> if the number being formatted is greater than or equal to
   * <code>choiceLimits[i]</code> and less than <code>choiceLimits[i+1]</code>.
   * @serial
   */
  this.choiceLimits = [];

  /**
   * A list of choice strings.  The formatter will return
   * <code>choiceFormats[i]</code> if the number being formatted is greater than or equal to
   * <code>choiceLimits[i]</code> and less than <code>choiceLimits[i+1]</code>.
   * @serial
   */
  this.choiceFormats = [];

  /**
   * Sets the pattern.
   * @param newPattern See the class description.
   */
  this.applyPattern = function (newPattern) {
    var segments = [null, null];
    var i;
    for (i = 0; i < segments.length; ++i) {
      segments[i] = new StringBuffer();
    }
    var newChoiceLimits = [];
    var newChoiceFormats = [];
    var count = 0;
    var part = 0;
    var startValue = 0;
    var oldStartValue = NaN;
    var inQuote = false;
    for (i = 0; i < newPattern.length; ++i) {
      var ch = newPattern.charAt(i);
      if (ch == '\'') {
        // Check for "''" indicating a literal quote
        if ((i + 1) < newPattern.length && newPattern.charAt(i + 1) == ch) {
          segments[part].append(ch);
          ++i;
        } else {
          inQuote = !inQuote;
        }
      } else if (inQuote) {
        segments[part].append(ch);
      } else if (ch == '<' || ch == '#' || ch == '\u2264') {
        if (segments[0] == "") {
          throw new IllegalArgumentException();
        }
        try {
          var tempBuffer = segments[0].toString();
          if (tempBuffer == "\u221E") {
            startValue = Infinity;
          } else if (tempBuffer == "-\u221E") {
            startValue = -Infinity;
          } else {
            startValue = parseFloat(segments[0]);
          }
        } catch (e) {
          throw new IllegalArgumentException();
        }
        if (ch == '<' && startValue != Infinity &&
          startValue != -Infinity) {
          startValue = this.nextDouble(startValue, true);
        }
        if (startValue <= oldStartValue) {
          throw new IllegalArgumentException();
        }
        segments[0] = new StringBuffer(); // .setLength(0);
        part = 1;
      } else if (ch == '|') {
        if (count == newChoiceLimits.length) {
          newChoiceLimits = this.doubleArraySize(newChoiceLimits);
          newChoiceFormats = this.doubleArraySize(newChoiceFormats);
        }
        newChoiceLimits[count] = startValue;
        newChoiceFormats[count] = segments[1].toString();
        ++count;
        oldStartValue = startValue;
        segments[1] = new StringBuffer();
        part = 0;
      } else {
        segments[part].append(ch);
      }
    }
    // clean up last one
    if (part == 1) {
      if (count == newChoiceLimits.length) {
        newChoiceLimits = this.doubleArraySize(newChoiceLimits);
        newChoiceFormats = this.doubleArraySize(newChoiceFormats);
      }
      newChoiceLimits[count] = startValue;
      newChoiceFormats[count] = segments[1].toString();
      ++count;
    }
    this.choiceLimits = newChoiceLimits;
    //        System.arraycopy(newChoiceLimits, 0, choiceLimits, 0, count);
    this.choiceFormats = newChoiceFormats;
    //        System.arraycopy(newChoiceFormats, 0, choiceFormats, 0, count);
  };

  /**
   * Returns pattern with formatted double.
   * @param number number to be formatted & substituted.
   * @param toAppendTo where text is appended.
   * @param status ignore no useful status is returned.
   */
  this.format = function (number, toAppendTo, status) {
    // find the number
    var i;
    for (i = 0; i < this.choiceLimits.length; ++i) {
      if (!(number >= this.choiceLimits[i])) {
        // same as number < choiceLimits, except catchs NaN
        break;
      }
    }
    --i;
    if (i < 0) i = 0;
    // return either a formatted number, or a string
    return toAppendTo.append(this.choiceFormats[i]);
  };

  /**
   * Finds the least double greater than d (if positive == true),
   * or the greatest double less than d (if positive == false).
   * If NaN, returns same value.
   *
   * This is a very simple implementation, but should serve our needs.
   */
  this.nextDouble = function (d, positive) {
    var smallNumber = 0.00001;
    return positive ? d + smallNumber : d - smallNumber;
  };

  this.doubleArraySize = function (array) {
    return array;
  };
}

//#####################################################
//###  MessageFormat
//#####################################################
window.SimpleMessageFormat = function () {
  this.applyPattern = function (pattern, args) {
    // result buffer
    var result = new StringBuffer();
    // current string position
    var curPos = 0;
    // position of the opening bracket
    var patternStart = -1;
    // found pattern
    var paramPattern = '';
    // pattern depth (number of opening brackets)
    var depth = 0;

    while ((patternStart = pattern.indexOf('{', curPos) + 1) > 0) {
      // copy everything before the opening bracket
      result.append(pattern.substring(curPos, patternStart - 1));
      // pattern depth +1
      depth++;
      curPos = patternStart;
      // find the colsing bracket and set curpos after that
      while (depth > 0) {
        var c = pattern.charAt(curPos++);
        switch (c) {
          // found an opening bracket > increase depth
          case '{': ++depth; break;
          // found a closing bracket > dencrease depth
          case '}': --depth; break;
        }
      }
      // parse the pattern
      paramPattern = pattern.substring(patternStart, curPos - 1);
      result.append(this.format(paramPattern, args));
    }
    result.append(pattern.substring(curPos, pattern.length));

    return result.toString();
  };

  this.format = function (pattern, args) {
    var commaPos = pattern.indexOf(',');
    var argId;
    var type = null;
    var subpattern = null;

    if (commaPos < 0) {
      argId = pattern;
    } else {
      argId = pattern.substring(0, commaPos);
      type = pattern.substring(commaPos + 1, commaPos = pattern.indexOf(',', commaPos + 1));
      subpattern = pattern.substring(commaPos + 1, pattern.length);
    }

    var argument = args[parseInt(argId)];

    if (type == 'choice') {
      // do choice
      subpattern = this.applyPattern(subpattern, args);
      var choice = new ChoiceFormat();
      choice.applyPattern(subpattern, args);
      return choice.format(argument, new StringBuffer());
    }

    return argument;
  };
}

window.hideErrorMessageForDatePicker = function (datepicker) {
  $(datepicker).focusout();
  if ($(datepicker).hasClass('error')) {
    $(datepicker).removeClass('error');
    $('div[for=' + $(datepicker).attr('id') + ']').hide();
  }
}

$(document).ready(function () {
  $('.fakeHeader, .availableOpts').click(function () {
    $(this).parent().children('.availableOpts').toggle('slow', function () {
      $(this).parent().toggleClass('opened');
    });
  });
});

$(document).ready(function () {
  /* hide control panel unnecessary portlets         */
  $('#panel-manage-portal li').has('a[href*="addresspointadminedit"]').hide();

  /* hide openx error messages and bad banners for not-admin users     */
  if ($('.portlet-boundary_openx_WAR_liferayportlets_').has('.portlet-topper').length <= 0) {
    $('.portlet-boundary_openx_WAR_liferayportlets_').has('.portlet-msg-error').hide();
    $('.portlet-boundary_openx_WAR_liferayportlets_:not(:has(img,embed))').hide().removeClass("margin-top margin-bottom");
  }

});

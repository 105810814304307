/* 
 *  IBA CZ Confidential
 *  (c) Copyright IBA CZ 2014 ALL RIGHTS RESERVED
 *  The source code for this program is not published or otherwise
 *  divested of its trade secrets.
 */

(function ($) {
  $.fn.dataTableExt.oPagination.guidelines = {
    'fnInit': function (oSettings, nPaging, fnCallbackDraw) {
      let fnClickHandler = function (e) {
        if (oSettings.oApi._fnPageChange(oSettings, e.data.action)) {
          fnCallbackDraw(oSettings);
        }
      };

      $(nPaging).attr('class', 'pagination');
      $(nPaging).append(
        `<div class="pagenum-wrap">
          <a class="prev o2f-chevron-left" href="#dataTableResult"></a>
        </div>
        <span class="pagination-summary"></span>
        <div class="pagenum-wrap">
          <a class="next o2f-chevron-right" href="#dataTableResult"></a>
        </div>`);
      $(nPaging).append(
        `<script type="text/javascript">
          $(document).ready(function() {
            let form = $('form[data-o2-id="pagination-form-${oSettings.sTableId}"]');
            ePortalInitValidations(form);
          });
        </script>`);

      let els = $('a', nPaging);
      let nPrev = els[0],
        nNext = els[1];

      oSettings.oApi._fnBindAction(nPrev, { action: 'previous' }, fnClickHandler);
      oSettings.oApi._fnBindAction(nNext, { action: 'next' }, fnClickHandler);

      // ID the first elements only
      if (!oSettings.aanFeatures.p) {
        nPaging.id = oSettings.sTableId + '_paginate';
        nPrev.id = oSettings.sTableId + '_previous';
        nNext.id = oSettings.sTableId + '_next';
      }
    },

    /*
     * Function: oPagination.full_numbers.fnUpdate
     * Purpose:  Update the list of page buttons shows
     * Returns:  -
     * Inputs:   object:oSettings - dataTables settings object
     *           function:fnCallbackDraw - draw function to call on page change
     */
    'fnUpdate': function (oSettings, fnCallbackDraw) {
      if (!oSettings.aanFeatures.p) {
        return;
      }

      let paginationStyles = {
        DEFAULT: 0,
        UNLIMITED: 1
      };

      let paginationStyle = paginationStyles.DEFAULT;
      let iPages = Math.ceil((oSettings.fnRecordsDisplay()) / oSettings._iDisplayLength);
      let iCurrentPage = Math.ceil(oSettings._iDisplayStart / oSettings._iDisplayLength) + 1;
      let sList = '';
      let iStartButton, iEndButton, i, iLen;
      let oClasses = oSettings.oClasses;
      oClasses.sPageButtonStaticDisabled = 'disabled';
      let anButtons, anStatic, nNode;
      let an = oSettings.aanFeatures.p;
      var fnBind = function (j) {
        let hrefId = $(this).attr('href');
        let disabledArrowBtn = $(this).parent().hasClass('disabled');

        if (hrefId === 'dataTableResult' && !disabledArrowBtn) {
          $('html, body').animate({
            scrollTop: $('.dataTable').first().offset().top - 150
          }, 0);
        }

        oSettings.oApi._fnBindAction(this, { 'page': j + iStartButton - 1 }, function (e) {
          // Use the information in the element to jump to the required page
          oSettings.oApi._fnPageChange(oSettings, e.data.page);
          fnCallbackDraw(oSettings);
          e.preventDefault();
        });
      };

      // Pages calculation
      iStartButton = 1;
      iEndButton = iPages;

      var fnInputBind = function () {
        let value = parseInt($(this).val());

        if (isNaN(value) || value < 1) {
          value = 1;
        } else if (value > iEndButton) {
          value = iEndButton;
        }

        // Use the information in the element to jump to the required page
        oSettings.oApi._fnPageChange(oSettings, value - 1);
        fnCallbackDraw(oSettings);
      };

      // use unlimited pagination style
      let dataPaginationtyle = $(`#${oSettings.sTableId}`).data('pagination-style');
      if (dataPaginationtyle && dataPaginationtyle === 'unlimited') {
        paginationStyle = paginationStyles.UNLIMITED;
      }

      if (iPages === 0) {
        $(`#${oSettings.sTableId}_paginate`).addClass('pagination-hidden');
      } else {
        $(`#${oSettings.sTableId}_paginate`).removeClass('pagination-hidden');
      }

      // build params object;
      let params = {
        'iCurrentPage': iCurrentPage,
        'oClasses': oClasses,
        'oSettings': oSettings,
        'iPages': iPages
      };

      // Build the dynamic list of pages
      sList = this.fnDefaultPaginationStyle(params); // if paginationStyles.DEFAULT
      if (paginationStyle === paginationStyles.UNLIMITED) {
        sList = this.fnUnlimitedPaginationStyle(params);
        $(`#${oSettings.sTableId}_paginate`).addClass('pagination-unlimited');
      }

      // Loop over each instance of the pager
      for (i = 0, iLen = an.length; i < iLen; i++) {
        nNode = an[i];
        if (!nNode.hasChildNodes()) {
          continue;
        }

        // Build up the dynamic list first - html and listeners
        $('span:eq(0)', nNode)
          .html(sList)
          .find('div a').each(fnBind);

        $('span:eq(0)', nNode).find('input').change(fnInputBind);

        // Update the permanent button's classes
        anButtons = nNode.getElementsByTagName('a');
        anStatic = [anButtons[0], anButtons[anButtons.length - 1]];

        $(anStatic).parent().removeClass(`${oClasses.sPageButton} ${oClasses.sPageButtonActive} ${oClasses.sPageButtonStaticDisabled}`);
        $(anStatic[0]).parent().addClass((iCurrentPage == 1) ? oClasses.sPageButtonStaticDisabled : '');
        $(anStatic[1]).parent().addClass(
          (iPages === 0 || iCurrentPage === iPages || oSettings._iDisplayLength === -1) ?
            oClasses.sPageButtonStaticDisabled : ''
        );
      }
    },

    'fnDefaultPaginationStyle': function (params) {
      return `<form role="form" class="pagination-form" data-o2-id="pagination-form-${params.oSettings.sTableId}">
                <div class="input-group page-wrap">
                  <input type="text" value="${params.iCurrentPage}" data-rule-regex="^[0-9]*$"/>
                </div>
                <span class="text">/</span>
                <span class="text all">${params.iPages}</span>
              </form>`;
    },
    'fnUnlimitedPaginationStyle': function (params) {
      return `<form role="form" class="pagination-form" data-o2-id="pagination-form-${params.oSettings.sTableId}">
                <div class="input-group page-wrap">
                  <input type="text" value="${params.iCurrentPage}" data-rule-regex="^[0-9]*$"/>
                </div>
              </form>`;
    }
  };
}($));

window.O2 = window.O2 || {};
window.O2.gdpr = window.O2.gdpr || {};
(function ($) {

  var defaults = {
    cookieNames: {
      consent: "X-O2SK-GDPR-CONSENT",
      source: "X-O2SK-GDPR-SOURCE",
      cms: "X-O2SK-GDPR-CMS",
      optIn: "X-O2SK-GDPR-OPTIN",
      gdprId: "X-O2SK-GDPR-ID",
      exponea: "__exponea_etc__"
    },
    cookieConfig: {
      domain: ".o2.sk",
      path: "/",
      expires: 50 * 365,
      httpOnly: false,
      secure: false
    },
    content: {
      message: "Na zlepšenie našich služieb používame cookies. O ich používaní a možnostiach nastavenia sa dozviete viac v",
      allow: "Povoliť cookies",
      link: "Zásadách ochrany osobných údajov",
      href: "https://www.o2.sk/ako-nakupovat/ochrana-sukromia",
      elementsLink: "Viac o cookies",
      exponea: {
        cookies_add: "Súhlas s použitím cookies",
        cookies_remove: "Odobratie súhlasu s použitím cookies",
        mkt_add: "Súhlas so spracovaním údajov na marketingové účely",
        mkt_remove: "Odobratie súhlasu so spracovaním údajov na marketingové účely"
      }
    },
    palette: {
      popup: {
        background: "rgba(133, 133, 133, 0.92)",
        text: "#fff"
      }
    },
    container: "body",
    isAssistantLoggedIn: false
  };

  window.O2.gdpr.settings = {};

  window.O2.gdpr.init = function (options) {
    window.O2.gdpr.settings = $.extend(true, {}, defaults, options);
    window.addEventListener("load", function () {
      window.O2.gdpr.recalculateCmsCookie();
      window.O2.gdpr.recalculateOptInCookie();
      window.O2.gdpr.initializeCookieConsent();
    });
  };

  window.O2.gdpr.recalculateCmsCookie = function () {
    var cookieNames = window.O2.gdpr.settings.cookieNames;
    if (typeof Liferay === "undefined" || ((typeof Liferay === "object")
      && (!Liferay.ThemeDisplay || !Liferay.ThemeDisplay.isSignedIn()))) {
      Cookies.remove(cookieNames.cms);
    }
  };

  window.O2.gdpr.recalculateOptInCookie = function () {
    var cookieNames = window.O2.gdpr.settings.cookieNames;
    var optIn = false;

    if (!window.O2.gdpr.settings.isAssistantLoggedIn) {
      var consent = Cookies.get(cookieNames.consent);
      var cms = Cookies.get(cookieNames.cms);
      var source = Cookies.get(cookieNames.source);
      optIn = (cms === 'true') || (consent === 'allow' && source === 'web');
    }

    Cookies.set(cookieNames.optIn, optIn, window.O2.gdpr.settings.cookieConfig)
  };

  window.O2.gdpr.successCallback = function (data) {
    var settings = window.O2.gdpr.settings;
    Cookies.set(settings.cookieNames.source, 'web', settings.cookieConfig);
    Cookies.set(settings.cookieNames.gdprId, data, settings.cookieConfig);

    window.O2.gdpr.recalculateOptInCookie();
    window.O2.gdpr.exponea.callCookiesChange(true, window.location.href)
  };

  window.O2.gdpr.failureCallback = function (xhr, textStatus, error) {
    console.error("Error occured during AJAX request:" + textStatus);
    window.ePortalAlert(null, "error during gdpr callback" + error, 3);
  };

  function deleteCookies() {
    var cookies = document.cookie.split("; ");
    for (var i = 0; i < cookies.length; ++i) {
      var myCookie = cookies[i];
      var pos = myCookie.indexOf("=");
      var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      if (name !== window.O2.gdpr.settings.cookieNames.consent) {
        Cookies.remove(name, window.O2.gdpr.settings.cookieConfig);
      }
    }
  }

  function callCookiesRevoke() {
    jQuery.ajax({
      url: window.O2.gdpr.settings.portalURL + "/delegate/cookie-revoke",
      data: {
        'domain': window.O2.gdpr.settings.cookieConfig.domain,
        'payload': 'Zakázať cookies'
      },
      success: deleteCookies,
      failure: window.O2.gdpr.failureCallback
    });
  }

  window.O2.gdpr.cleanCookiesAndStorage = function () {
    localStorage.clear();
    sessionStorage.clear();
    callCookiesRevoke();
  };

  window.O2.gdpr.logRequest = function () {
    var payload = {
      content: window.O2.gdpr.settings.content
    };

    jQuery.ajax({
      url: window.O2.gdpr.settings.portalURL + "/delegate/cookie-consent",
      data: {
        'domain': window.O2.gdpr.settings.cookieConfig.domain,
        'payload': JSON.stringify(payload)
      },
      success: window.O2.gdpr.successCallback,
      failure: window.O2.gdpr.failureCallback
    })
  };

  window.O2.gdpr.initializeCookieConsent = function () {
    var settings = window.O2.gdpr.settings;
    var cookieConsentConfig = {
      cookie: {
        name: settings.cookieNames.consent,
        path: settings.cookieConfig.path,
        domain: settings.cookieConfig.domain,
        expiryDays: settings.cookieConfig.expires
      },
      container: jQuery(settings.container)[0],
      layout: "o2layout",
      layouts: {
        o2layout: "{{messagelink}}{{compliance}}{{customClose}}"
      },
      palette: settings.palette,
      position: "bottom-left",
      static: false,
      type: "opt-in",
      revokable: false,
      animateRevokable: false,
      law: {
        countryCode: "SK"
      },
      compliance: {
        "opt-in": "<div class=\"cc-compliance\">{{allow}}</div>"
      },
      elements: {
        allow: "<a aria-label=\"" + settings.content.allow + "\" tabindex=\"0\" " + "class=\"cc-btn cc-allow btn btn-white btn-xs\" data-o2-id=\"cookieConsent.allow\">{{allow}}</a>",
        messagelink: "<span id=\"cookieconsent:desc\" class=\"cc-message\">{{message}} " + "<a aria-label=\"" + settings.content.elementsLink + "\" tabindex=\"0\" " + "class=\"cc-link\" href=\"{{href}}\" target=\"_blank\" data-o2-id=\"cookieConsent.link\">{{link}}</a>" + "</span>",
        customClose: "<span class=\"cookie-close btn btn-white btn-xs visible-xs visible-sm\" onclick=\"window.O2.gdpr.cookieConsent.close()\">&#x274c;</span>"
      },
      content: settings.content,
      onStatusChange: function (status, chosenBefore) {
        if (status === 'allow') {
          window.O2.gdpr.logRequest();
        } else if (status === 'deny') {
          window.O2.gdpr.cleanCookiesAndStorage();
        }
        window.O2.gdpr.recalculateOptInCookie();
      },
      onRevokeChoice: window.O2.gdpr.cleanCookiesAndStorage

    };
    var onLoadComplete = function (popup) {
      window.O2.gdpr.cookieConsent = popup;
      window.gdprCookieConsent = popup;
    };

    var onLoadError = function (error) {
      window.ePortalAlert(null, "error during gdpr init" + error, 3);
    };
    window.cookieconsent.initialise(cookieConsentConfig, onLoadComplete, onLoadError);
  };


  window.O2.gdpr.exponea = {
    callCookiesChange: function (approved, url, ip) {
      if (window.exponea) {
        var settings = window.O2.gdpr.settings;
        var texts = settings.content.exponea;
        window.exponea.track('consent', {
          'location': url,
          'action': approved ? "accept" : "withdrawn",
          'category_label': "prehliadacovy_suhlas",
          'ip': ip,
          'message': approved ? texts.cookies_add : texts.cookies_remove,
          'exponea_cookie': Cookies.get(settings.cookieNames.exponea)
        });
      } else {
        console.warn("cannot execute exponea tracking")
      }
    }, callMktChange: function (approved, url, ip) {
      if (window.exponea) {
        var settings = window.O2.gdpr.settings;
        var texts = settings.content.exponea;
        window.exponea.track('consent', {
          'location': url,
          'action': approved ? "accept" : "withdrawn",
          'category_label': "ucastnicky_mkt_suhlas",
          'ip': ip,
          'message': approved ? texts.mkt_add : texts.mkt_remove,
          'exponea_cookie': Cookies.get(settings.cookieNames.exponea)
        });
      } else {
        console.warn("cannot execute exponea tracking")
      }
    }
  };

}($));

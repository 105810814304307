/*!
 * o2-mobile-select v1.0.0
 *
 * Copyright 2016 o2-mobile-select
 */

/**
 * This plugin is used to render bootstrap select on desktop and native html select on mobile devices.
 * In case of mobile devices, it can be overridden with useNativeOnMobile=false to use bootstrap select.
 */
!function ($) {

  'use strict';

  var isMobileDevice = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobileDevice.Android() || isMobileDevice.BlackBerry() || isMobileDevice.iOS() || isMobileDevice.Opera() || isMobileDevice.Windows());
    }
  };

  var MobileSelectpicker = function (element, options, e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.$element = $(element);

    //Merge defaults, options and data-attributes to make our options
    this.options = $.extend({}, $.fn.o2mobileselectpicker.defaults, this.$element.data(), typeof options == 'object' && options);

    //Expose public methods
    this.val = MobileSelectpicker.prototype.val;
    this.init();
  };

  MobileSelectpicker.prototype = {

    constructor: MobileSelectpicker,

    init: function () {
      this.multiple = this.$element.prop('multiple'); //todo TS - add handling for "multiple selectpicker"

      var selectNode = this.$element;
      if (selectNode) {
        var containerNode = selectNode.parent();
        if (containerNode) {
          containerNode.addClass('native-select');

          var labelNode = containerNode.find('label.control-label:first');
          if (labelNode) {
            var outerContainer = containerNode.closest('.native-select-with-button');
            if (outerContainer.length) {     // need to shuffle elements because of a button beside the select
              outerContainer.addClass('native-select');

              var colNode = selectNode.closest("[class*='col-']");
              var col = 'col-sm-12';
              if (colNode) {
                col = $.grep(colNode.attr('class').split(' '), function (v, i) {
                  return v.indexOf('col-') === 0;
                }).join(' ');
              }

              var rowWrapper = $('<div class="row" style="margin-left: 0px; margin-right: 0px;"></div>');
              rowWrapper.append(outerContainer.children());
              outerContainer.append(rowWrapper);

              var firstChild = $('<div class="row" style="margin-left: 0px; margin-right: 0px;"></div>');
              var secondChild = $('<div class="' + col + '"></div>');
              secondChild.append(labelNode);
              firstChild.append(secondChild);
              outerContainer.prepend(firstChild);
            } else {
              if (!labelNode.isBefore('select.selectpicker')) {
                labelNode.insertBefore(selectNode);
              }
            }


          }
        }

        selectNode.show();
      }
    },

    val: function (value) {

      if (value !== undefined) {
        this.$element.val(value);

        this.$element.change();
        return this.$element;
      } else {
        return this.$element.val();
      }
    }
  };

  $.fn.isAfter = function (sel) {
    return this.prevAll(sel).length !== 0;
  };

  $.fn.isBefore = function (sel) {
    return this.nextAll(sel).length !== 0;
  };

  $.fn.o2mobileselectpicker = function (option, event) {
    //get the args of the outer function..
    var args = arguments;
    var value;
    var chain = this.each(function () {
      if ($(this).is('select')) {

        var $this = $(this),
          data = $this.data('selectpicker'),
          options = typeof option == 'object' && option;

        var config = $.extend({}, $.fn.o2mobileselectpicker.defaults, options);

        if (isMobileDevice.any() && config.useNativeOnMobile) {
          if (!data) {
            $this.data('selectpicker', (data = new MobileSelectpicker(this, config, event)));
          } else if (options) {
            for (var i in options) {
              data.options[i] = options[i];
            }
          }

          if (typeof option == 'string') {
            //Copy the value of option, as once we shift the arguments
            //it also shifts the value of option.
            var property = option;
            if (data[property] instanceof Function) {
              [].shift.apply(args);
              value = data[property].apply(data, args);
            } else {
              value = data.options[property];
            }
          }
        } else {
          $this.selectpicker(option, event);   // call bootstrap-select
        }
      }
    });

    if (value !== undefined) {
      return value;
    } else {
      return chain;
    }
  };

  $.fn.o2mobileselectpicker.defaults = {
    useNativeOnMobile: true
  };

}($);

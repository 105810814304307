/** 
 * Basic implementation of session storage - sessionStorageO2
 */

window.SessionStorageO2 = function () {
  var that = this;

  /**
   * Private function.    
   * Prepare data for store to storage    
   */
  function prepareDataStorage(data, key) {
    let parsedData = JSON.stringify(data);
    let storageData = JSON.parse(that.getDataFromStorage(key));
    if (storageData != null) {
      if (storageData.items) {
        storageData.items.push(parsedData);
      } else {
        let newStorageData = { items: [parsedData] };
        storageData = newStorageData;
      }
    } else {
      let newStorageData = { items: [parsedData] };
      storageData = newStorageData;
    }
    return storageData;
  }

  this.checkStorage = function () {
    return (typeof (Storage) !== 'undefined') ? true : false;
  };

  this.getDataFromStorage = function (key) {
    if (that.checkStorage()) {
      if (typeof key === 'undefined' || key === null || !key) {
        return sessionStorage.getItem('data');
      } else {
        return sessionStorage.getItem(key);
      }
    } else {
      return null;
    }
  };

  /**
   * This metod set data to session storage for defined key.
   * If for defined key exists data, then will be replaced.         
   */
  this.setDataToStorage = function (key, data) {
    if (that.checkStorage()) {
      sessionStorage.setItem(key, data);
    }
  }

  /**
   * Add data to session storage for defined key.
   * If key is not defined, then will be added to storage for defined key 'data'.
   * Data for defined key are inserted to array. Data will be not replaced.               
   */
  this.addDataToStorage = function (data, key) {
    if (that.checkStorage()) {
      var storageData = prepareDataStorage(data, key);
      try {
        // if is key undefined or null or empty string
        if (typeof key === 'undefined' || key === null || !key) {
          sessionStorage.setItem('data', JSON.stringify(storageData));
        } else {
          sessionStorage.setItem(key, JSON.stringify(storageData));
        }
      } catch (e) {
        console.error("Error: Saving to storage.");
      }
    }
  };
  // check local storage
  that.checkStorage();
}
